import { useNavigate, useSearchParams } from "react-router-dom";
import { generateUniqueId } from "../common/utils";
import useSearchState from "../components/hooks/useSearchState";
import { User } from "../constants/interface";

export const TOGGLE_PARENT_LAYOUT_STYLE = "TOGGLE PARENT LAYOUT STYLE";
export const GET_VENDOR_DETAILS = "GET VENDOR DETAILS";
export const GET_OUTLET_DETAILS = "GET OUTLET DETAILS";
export const STORE_FILTERS = "STORE FILTERS";
export const LOAD_FILTERS = "LOAD FILTERS";
export const DELETE_FILTERS = "DELETE FILTERS";

export function updateUser(user: User | null) {
  return {
    type: "UpdateUser",
    payload: user,
  } as const;
}

export function getVendorDetails(vendor: any, vendorType: string){
  return {
    type: vendorType === "outlet" ? GET_OUTLET_DETAILS : GET_VENDOR_DETAILS,
    payload: vendor
  }
}

export const toggleRootLayoutStyle = (value: boolean) => ({ type: TOGGLE_PARENT_LAYOUT_STYLE,  payload: value});

export type RootLayoutActionType = ReturnType<typeof toggleRootLayoutStyle>;
export type ActionType = ReturnType<typeof updateUser>;
export type VendorActionType = ReturnType<typeof getVendorDetails>;

export function setOrUpdateFilters(payload: { 
  filterName: string, 
  filterValues: any,
  filterId: string,
}){
  const { 
    filterName, 
    filterValues,
    filterId 
  } = payload;

  const filters = JSON.parse(sessionStorage.getItem(filterId) || '{}') ;
  let filterObject = {};

  if(filters?.filter){
    filterObject = { ...filters?.filter };
  }
  sessionStorage.setItem(
    filterId, 
    JSON.stringify({ 
      filter: { ...filterObject, [filterName]: filterValues } 
    })
  );

  return {
    type: STORE_FILTERS,
    payload: {
      filterId: filterId, 
      filters: { 
        filter: { ...filterObject, [filterName]: filterValues } 
      },
    }
  }
}

export function loadFiltersByFilterId(filterId: string){
  if(filterId){
    const filters = JSON.parse(sessionStorage.getItem(filterId) || '{}') ;
    return {
      type: LOAD_FILTERS,
      payload: {
        filterId: filterId, 
        filters: filters,
      }
    }
  }
  return {
    type: LOAD_FILTERS,
    payload: {}
  }
}

export function removeFiltersById(filterId: string){
    const filters = sessionStorage.removeItem(filterId);
    return {
      type: DELETE_FILTERS,
      payload: {}
    }
}

export function clearSessionStorage(){
  sessionStorage.clear();
    return {
      type: DELETE_FILTERS,
      payload: {}
    }
}
