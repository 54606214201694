import { Outlet, User, Vendor } from "../constants/interface";
import { ActionType, VendorActionType, GET_OUTLET_DETAILS, GET_VENDOR_DETAILS, RootLayoutActionType, TOGGLE_PARENT_LAYOUT_STYLE, STORE_FILTERS, LOAD_FILTERS, DELETE_FILTERS } from "./actions";

export const userReducer = (state: User | null = null, action: ActionType) => {
  switch (action.type) {
    case "UpdateUser":
      return (state = action.payload);
    default:
      return state;
  }
};

export const vendorReducer = (
    state: {
      vendorDetails: Vendor;
      outletDetails: Outlet;
    } = {
      vendorDetails: JSON.parse(localStorage.getItem("vendorDetails") || "{}"), 
      outletDetails: JSON.parse(localStorage.getItem("outletDetails") || "{}")
    }, 
    action: VendorActionType
  ) => {
  switch (action.type) {
    case GET_OUTLET_DETAILS:
      localStorage.setItem("outletDetails", JSON.stringify(action.payload))
      return {
        ...state,
        outletDetails: action.payload
      };
    case GET_VENDOR_DETAILS:
      localStorage.setItem("vendorDetails", JSON.stringify(action.payload))
      return {
        ...state,
        vendorDetails: action.payload
      }
    default:
      return state;
  }
};

export const rootLayoutReducer = (
  state: {
    applyRootLayoutStyle: boolean,
  } = {
    applyRootLayoutStyle: true
  },
  action: RootLayoutActionType
) => {
  switch (action.type) {
    case TOGGLE_PARENT_LAYOUT_STYLE:
      return {
        ...state,
        applyRootLayoutStyle: action.payload,
      }
    default:
        return state;
  }
}

export const filterReducer = (
  state: {
    filtersData: any
  } = { filtersData: {} }, action: any
) => {
  switch (action.type) {
    case STORE_FILTERS:
      return { 
        ...state, 
        filtersData: {
          ...state.filtersData,
          [action.payload.filterId]: action.payload.filters 
        }
      };
    case LOAD_FILTERS: 
      return {
        ...state, 
        filtersData: {
          ...state.filtersData,
          [action.payload.filterId]: action.payload.filters
        }
      }
    case DELETE_FILTERS:
      return {
        ...state, 
        filtersData: {}
      }
    default:
      return state;
  } 
}