import LoadableComponent from "../components/loadable-component/LoadableComponent";
import authRoles from "../constants/authConstants";

export const ExternalPageConfig = {
  auth: authRoles.external,
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: '/profile',
      component: LoadableComponent({
        loader: () => import("../main/external-user-profile"),
      }),
    },
    {
      path: '/external/outlets',
      component: LoadableComponent({
        loader: () => import("../main/outlets-list"),
      }),
    },
    // ...CommonRouteConfig
  ],
};
