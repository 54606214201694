import { network } from "../common/network";
import { notify } from "../common/notify";
import apiConsts from "../constants/apiConstants";

class ManagerService {
  public async getAll(params: any) {
    const restaurantId = params.extras.restaurantId;
    const response: any = await network(
      `/v2/vendors/${restaurantId}/manager/`,
      {
        page: params.paginate.current - 1,
        rowsPerPage: params.paginate.pageSize,
        searchText: params.searchText,
        isArchived: params.isArchived,
        characterFilter: params.characterFilter,
        sortBy: params.sorter ? { [params.sorter.columnKey] : params.sorter.order === 'ascend' ? 1 : params.sorter.order === 'descend' ? -1 : params.sorter.order } : {},
      },
      "GET"
    );
    if(response?.success)
      return {
        data: response?.data,
        totalRecord: response?.totalRecord,
      };
    
    return {data: [], totalRecord: 0};
  }
  //! Done
  public async assignManager(
    params: any,
    restaurantId: string,
    outletId: string
  ) {
    const response: any = await network(
      `/v2/vendors/${restaurantId}/outlet/${outletId}/manager/assign/${params.id}`,
      params,
      "PUT"
    );
    if (response.success) {
      notify("success", "Manager added successfully.");
      return response?.data;
    }
    if (response?.messageCode === "ALREADY_ASSIGNED") {
      notify("error", "Manager Already Assigned.");
      // return response?.data;
      throw response;
    }
    notify("error", response.message);
    throw response;
  }

  public async unassignManager(
    params: any,
    restaurantId: string,
    outletId: string
  ) {
    const response: any = await network(
      `/v2/vendors/${restaurantId}/outlet/${outletId}/manager/unassign/${params.id}`,
      params,
      "PUT"
    );
    if (response?.success) {
      notify("success", "Manager Removed.");
      return response?.data;
    }
    notify("error", response?.message);
    throw response;
  }
  //! Done
  public async getManagerData(restaurantId: string, searchText: string) {
    const response = await network(`/v2/vendors/${restaurantId}/manager`, {
      searchText: searchText.trim(),
    });
    return response?.data || [];
  }

  public async assignedManagers(restaurantId: string, outletId: string) {
    const response = await network(
      `${apiConsts.vendorURI}/${restaurantId}/outlet/${outletId}/manager/assign`
    );
    return response?.data || [];
  }

  public async getOne(employeeId: string, onSuccess: Function) {
    const response = await network(
      `${apiConsts.employeeURI}/${employeeId}`,
      {},
      "GET"
    );
    return response;
  }
  // new manager API
  public async getOneManager(
    vendorId: string ,
    managerId: string
  ) {
    const response = await network(
      `/v2/vendors/${vendorId}/manager/${managerId}`,
      {},
      "GET"
    );
    return response;
  }
  public async create(vendorId: string, newManager: any) {
    const response = await network(
      `/v2/vendors/${vendorId}/manager/`,
      newManager,
      "POST"
    );
    if(response?.success) {
      notify("success","Manager Created Successfully!");
      return response;
    }
    notify("error",response?.message);
    throw response;
  }
  public async update(
    vendorId: string,
    managerId: string,
    updatedManager: any
  ) {
    const response = await network(
      `/v2/vendors/${vendorId}/manager/${managerId}`,
      updatedManager,
      "PUT"
    );
    if(response?.success) {
      notify("success","Manager Updated Successfully!");
      return response;
    }
    notify("error",response.message);
    throw response;
  }
  public async archiveManager(vendorId: string | undefined, managerId: string) {
    const response = await network(
      `/v2/vendors/${vendorId}/manager/archive/${managerId}`,
      undefined,
      "DELETE"
    );
    if(response?.success){
      notify("success", "Manager archived successfully!");
      return response;
    }
    notify("error", response?.message);
    throw response;
  }
  public async unArchiveManager(
    vendorId: string | undefined,
    managerId: string
  ) {
    const response = await network(
      `/v2/vendors/${vendorId}/manager/unarchive/${managerId}`,
      undefined,
      "DELETE"
    );
    if(response?.success){
      notify("success", "Manager unarchived successfully!")
      return response;
    }
    notify("error", response?.message);
    throw response;
  }
  public async generatePin(vendorId: string) {
    const response = await network(
      `/v2/vendors/${vendorId}/manager/generate-pin`,
      {},
      "GET"
    );
    if(response?.success) {
      notify("success","Pin Generated Successfully!");
      return response;
    }
    notify("error",response.message);
    throw response;
  }

  public async getAllEmployees(vendorId: string, outletId: string, params:any) {
    const response = await network(
      `/v2/vendors/${vendorId}/employee/all/${outletId}`,
      params,
      "GET"
    );
    if(response?.success){
      return response?.data;
    }
    throw response;
  }

  public async getAssignedOutletsBySite(vendorId: string, siteId: string) {
    const response = await network(
      `${apiConsts.vendorURI}/${vendorId}/${siteId}/assignedSiteOutlet`,
      {},
      "GET"
    );
    if(response?.success){
      return response?.data;
    }
    throw response;
  }
}

export const managerService = new ManagerService();
