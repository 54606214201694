import { createStore, combineReducers, Store } from "redux";
import { AppState } from "../constants/interface";
import { rootLayoutReducer, userReducer, vendorReducer, filterReducer } from "./reducer";


const rootReducer = combineReducers<AppState>({
  user: userReducer,
  vendor: vendorReducer,
  rootLayout: rootLayoutReducer,
  filterReducer: filterReducer,
});

function configureStore(): Store<AppState> {
  const store = createStore(rootReducer, undefined);
  return store;
}

export const store = configureStore();
