import { useState } from "react";
import { Row, Form, Input, Typography } from "antd";
import { useFormik } from "formik";
import { StyledLink } from "../utils/StyledLink";
import * as yup from "yup";
import jwtService from "../../services/jwtService";
const { Title, Text } = Typography;
import logo from "../../assets/images/new_logo.png";
import { CustomButtonLoader } from "../custom-components/custom-buttom-with-loader";
import { notify } from "../../common/notify";

const ForgotPassword = () => {
  const [isLoading, setIsLoading] = useState(false);
  const formik = useFormik({
    initialValues: {
      email: ""
    },
    validationSchema: yup.object().shape({
      email: yup.string().trim().email("Must be a valid Email!").required().label("Email").max(255),
    }),
    onSubmit: async (values) => {
      setIsLoading(true);
      await jwtService.sendForgotPasswordLink(values.email)
        .then((response) => {
          notify("success", "Reset password link sent.")
        })
        .catch((response) => notify("error", response))
        .finally(() => setIsLoading(false));
    }
  });

  return (
    <div className="login">
      <div className="login-form">
        <div>
          <img
            src={logo}
            alt="logo"
            className="noq-favicon"
          />
        </div>
        <div>
          <h4>RECOVER YOUR PASSWORD</h4>
        </div>
        <div className="w-100">
          <Form
            onFinish={formik.handleSubmit}
            name="basic"
            autoComplete="off"
          >
            <Row>
              <label>
                <h3>Email</h3>
              </label>
            </Row>
            <Row>
              <Input
                {...formik.getFieldProps("email")}
                size="large"
                placeholder="jhon.doe@noq.staging"
              />
            </Row>
            <div>{formik.touched.email && formik.errors.email ? <Text type="danger">{formik.errors.email}</Text> : null}</div>
            <div className="mt-1">
              <CustomButtonLoader isLoading={isLoading} type="primary" size="large" htmlType="submit" isBlock={true} btnText="SEND RESET LINK" />
            </div>
          </Form>
        </div>
        <div className="mt-1">
          <StyledLink to="/login">
            <Title type="secondary" level={4}>Go back to login</Title>
          </StyledLink>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;