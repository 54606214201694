import Layout from "antd/lib/layout/layout";
import Sider from "antd/lib/layout/Sider";
import { Content } from "antd/lib/layout/layout";
import { Sidebar } from "../sidebar";
import { createContext, Fragment, ReactNode, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../constants/interface";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { AppUserType } from "../../constants/AppConstant";
import Zendesk, { ZendeskAPI } from "../../ConfigZendesk";
import apiConsts from "../../constants/apiConstants";
import { getVendorDetails } from "../../store/actions";
import { vendorService } from "../../services/VendorService";
import { notify } from "../../common/notify";
import AppHeader from "../app-header";

const LayoutComponent = ({ children }: { children: ReactNode }) => {
  const user = useSelector((state: AppState) => state.user);
  const shouldApplyRootLayoutStyle = useSelector((state: AppState) => state.rootLayout.applyRootLayoutStyle);
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const dispatch = useDispatch();

  const zendeskKey = apiConsts.zendeskKey;
  const handleLoaded = () => {
    ZendeskAPI("messenger", "show");
  };

  useEffect(() => {
    if (user && user.userType !== AppUserType.ADMIN && params.vendorId && (params.vendorId !== user.restaurantDetails)) {
      navigate("/unauthorized-access", { replace: true })
    }
    const contentElement = document.querySelector(".content-container");
    if (contentElement) {
      contentElement.scrollTop = 0;
    }
  } ,[location])

  useEffect(() => {
    if(params.vendorId){
      vendorService.getOne(params.vendorId)
      .then(res => {
        dispatch(getVendorDetails(res, "vendor"));
      }).catch(err => {
        dispatch(getVendorDetails(null, "vendor"));
        notify("error", "Business not found!")
      })
    }
  },[params.vendorId]);

  return (
    <Layout className="app-root-layout">
      {user ? <AppHeader /> : null}
      <Layout className="main-content-layout-with-sidebar">
        {user ? (
            <Fragment>
              <Sider className="app-sidebar">
                <Sidebar />
              </Sider>
              <Layout className="main-section-layout">
                <Content className={shouldApplyRootLayoutStyle ? "app_layout_content_container content-container" : "content-container"}>
                    {children}
                    { apiConsts.DEV ? <Zendesk defer zendeskKey={zendeskKey} onLoaded={handleLoaded} /> : null }
                </Content>
              </Layout>
            </Fragment>
        ) : (
          <Content>{children}</Content>
        )}
      </Layout>
    </Layout>
  );
};

export default LayoutComponent;
