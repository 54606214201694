import { CaretDownOutlined, LogoutOutlined, UserOutlined } from "@ant-design/icons";
import { Col, Dropdown, Layout, Menu, Row } from "antd";
import Title from "antd/lib/typography/Title";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/images/noq_header2_logo.png";
import { AppUserType } from "../../constants/AppConstant";
import { AppState, User } from "../../constants/interface";
import { getVendorDetails, updateUser } from "../../store/actions";
import { StyledLink } from "../utils/StyledLink";
import jwtService from "../../services/jwtService";
import "./app-header.less"

const { Header } = Layout;

const AppHeader = () => {
    const userRedux: User | null = useSelector((state: AppState) => state.user);
    const vendorRedux = useSelector((state:AppState) => state?.vendor?.vendorDetails);
    const role: string | undefined = userRedux?.userType;
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const getProfilePath = () => {
        if(role === AppUserType.ADMIN){
            return '/profile-admin'
        }else{
            return "/profile"
        }
    }

    const logoutHandler = () => {
        dispatch(updateUser(null));
        dispatch(getVendorDetails(null, "vendor"));
        dispatch(getVendorDetails(null, "outlet"));
        navigate("/login", { replace: true });
        jwtService .logout();
    }

    const menu = (
        <Menu>
            <Menu.Item key="profile" icon={<UserOutlined />} >
                <StyledLink to={getProfilePath()}>Profile</StyledLink>
            </Menu.Item>
            <Menu.Divider />
            <Menu.Item key="logout" icon={<LogoutOutlined/>} onClick={logoutHandler}>
                Logout
            </Menu.Item>
        </Menu>
    );

    return (
        <Header className="app_header">
            <Row align="middle" justify="space-between" style={{ width: '100%' }}>
                <Col>
                    <Title level={4} style={{ color: "#fff" }} className="mb-0px">
                        <b>{role === AppUserType.VENDOR || role === AppUserType.MANAGER ? vendorRedux?.businessName?.toUpperCase() : role}</b>
                    </Title>
                </Col>
                <Col>
                    <Row justify="center">
                        <div className="noq-logo">
                            <img src={logo} alt="logo192" />
                        </div>
                    </Row>
                </Col>
                <Col>
                    {(userRedux && (userRedux?.firstName || userRedux?.lastName)) ? 
                        <Dropdown overlay={menu} trigger={['click']} className="user-select" overlayClassName="user-dropdown-menu" placement="bottomRight" >
                            <Row justify="end">
                                <a onClick={(e) => e.preventDefault()}>
                                    <span>{`${userRedux?.firstName || ""} ${userRedux?.lastName || ""}`}</span>&nbsp; <CaretDownOutlined />
                                </a>
                            </Row>
                        </Dropdown> : <></>
                    }
                </Col>
            </Row>
        </Header>
    )
}

export default AppHeader;