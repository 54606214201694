import { useState, useEffect } from "react";
//https://betterprogramming.pub/usebreakpoint-hook-get-media-query-breakpoints-in-react-3f1779b73568
export const useBreakpoints = () => {
  const [height, setHeight] = useState(window.innerHeight);
  const [width, setWidth] = useState(window.innerWidth);

  const calcWindowSize= () => {
    //https://docs-lodash.com/v4/throttle/
    // throttle(() => {
      setHeight(window.innerHeight);
      setWidth(window.innerWidth);
    // },200);
  }

  useEffect(() => {
    addEventListener("resize",calcWindowSize);
    return () => removeEventListener("resize",calcWindowSize);
  },[]);

  return {height, width};
};