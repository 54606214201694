import axios from "axios";
import jwtDecode from "jwt-decode";
import history from "../history";
import { MyToken, PasswordObject, User } from "../constants/interface";
import apiConsts from "../constants/apiConstants";
import _ from "lodash";
import EventEmitter from "eventemitter3";
import { network } from "../common/network";

const userURI = apiConsts.userURI;

class jwtService extends EventEmitter {
  init() {
    this.setInterceptors();
    this.handleAuthentication();
    window.addEventListener('storage', e => {
      if (e.key === 'jwt_access_token') {
        window.location.reload();
      }
    });
  }

  setInterceptors = () => {
    axios.interceptors.request.use(
      async config => {
        const token = this.getAccessToken();
        if(token) {
          config.headers = { 
            'Authorization': `Bearer ${token}`,
          }
        }
        return config;
      },
      error => {
        Promise.reject(error)
    });
    axios.interceptors.response.use(
      (response) => {
        return response;
      },
      async (err) => {
        const originalConfig = err.config;
        if (originalConfig && !originalConfig.url.includes(`${userURI}/login`)  && err.response) {
          // Access Token was expired
          if (err.response.status === 401 && !originalConfig._retry) {
            originalConfig._retry = true;
            try {
              const rs = await network(
                `${apiConsts.userURI}/generate-auth-token`,
                {
                  requestToken: this.getRefreshToken(),
                },
                "POST"
              );
              if(rs.data && rs.data.data.token) {
                const { token } = rs.data.data;
                this.setSession(token);
              } else if (!originalConfig.__isRetryRequest) {
                // if you ever get an unauthorized response, logout the user
                this.emit("onAutoLogout", "Invalid Access Token!");
              }
              return axios(originalConfig)
            } catch (_error) {
              return Promise.reject(_error);
            }
          }
        }
        return Promise.reject(err);
      }
    );
  };

  handleAuthentication = () => {
    const access_token = this.getAccessToken();

    if (!access_token) {
      // NOTE: No access to any other page without the token and redirect user to login page.
      this.emit("onAutoLogout");
      return;
    }

    if (this.isAuthTokenValid(access_token)) {
      this.setSession(access_token);
      this.emit("onAutoLogin", true);
    } else {
      this.setSession(null, null);
      this.emit("onAutoLogout", "Access Token expired");
    }
  };

  signInWithEmailAndPassword = (email: string, password: string) => {
    return new Promise((resolve, reject) => {
      network(
        `${userURI}/login?portal=${true}`,
        {
          email,
          password,
        },
        "POST"
      )
        .then((response) => {
          if (response && response.data && response.success) {
            const userData = response.data.user;
            const token = userData.token;
            delete userData.token;
            this.setSession(token, userData);
            this.setRefreshToken(response.data.refreshToken)
            history.push({
              // pathname: '/home'
              pathname: "/",
            });
            resolve(userData);
          } else if (response?.errors) {
            reject(response.errors);
          } else {
            reject(response?.message);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  // FIXME: Use this only in DEVELOPMENT
  signInWithToken = (): Promise<User> => {
    const token = this.getAccessToken();
    return new Promise((resolve, reject) => {
      const userData = this.getCurrentUser();
      if (!userData) {
        reject(null);
      }
      this.setSession(token, userData);
      resolve(userData);
    });
  };

  updateUserData = (userId: string, user: User) => {
    return new Promise((resolve, reject) => {
      network(`${userURI}/${userId}`, { ...user }, "PUT")
        .then((response) => {
          const resp = response?.data; // NOTE: response.data is what the backend actually responds.
          if (resp) {
            if (resp.data) {
              const token = this.getAccessToken();
              const userData: User = resp.data.user;
              this.setSession(token, userData); // NOTE: REMOVE SETTING USER IN SESSION IN PROD.
              resolve(userData);
            } else {
              reject(resp.errors);
            }
          } else {
            reject(resp.errors);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  changeUserPassword = (pwdObj: PasswordObject) => {
    return new Promise((resolve, reject) => {
      network(
        `${userURI}/password-change/${pwdObj.userId}`,
        { ...pwdObj },
        "PUT"
      )
        .then((response) => {
            if (response.success) {
              const userData: User = response.data;
              resolve(userData);
            } else {
              reject(response.message);
            }
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  getUserDetailsById(userId: string) {
    const uri = `${userURI}/${userId}`;

    return new Promise((resolve, reject) => {
      network(uri, {}, "GET")
        .then((response) => {
          const user: User = response?.data.data.user;
          const access_token = this.getAccessToken();
          this.setSession(String(access_token), user);
          resolve(user);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  sendForgotPasswordLink(email: string) {
    const uri = `${userURI}/reset-password-request/${email}`;

    return new Promise((resolve, reject) => {
      network(uri, {}, "GET")
        .then((response) => {
          if(response.success) resolve(response?.message);
          reject('Email address not found');
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  setSession = (access_token: string | null, user?: User | null) => {
    // FIXME: See if the user details being saved in local storage can be removed and be stored in runtime memory.
    if (access_token) {
      localStorage.setItem("jwt_access_token", access_token);
      if (user) {
        localStorage.setItem("user", JSON.stringify(user));
      }
      axios.defaults.headers.common["Authorization"] = "Bearer " + access_token;
    } else {
      localStorage.removeItem("jwt_access_token");
      localStorage.removeItem("user");
      localStorage.removeItem("vendorDetails");
      localStorage.removeItem("outletDetails");
      delete axios.defaults.headers.common["Authorization"];
    }
  };

  setRefreshToken = (refreshToken: string) => {
    if (refreshToken) {
      localStorage.setItem("jwt_refresh_token", refreshToken);
    } else {
      localStorage.removeItem("jwt_refresh_token");
    }
  }

  logout = () => {
    // history.replace({ pathname: "/login" });
    this.setSession(null, null);
  };

  isAuthTokenValid = (access_token: string) => {
    if (!access_token) {
      return false;
    }
    const decoded = jwtDecode<MyToken>(access_token);
    const currentTime = Date.now() / 1000;
    if (decoded.exp < currentTime) {
      return false;
    } else {
      return true;
    }
  };

  getAccessToken = () => {
    return window.localStorage.getItem("jwt_access_token");
  };

  getRefreshToken = () => {
    return localStorage.getItem('jwt_refresh_token')
  }

  getCurrentUser = () => {
    return JSON.parse(String(window.localStorage.getItem("user")));
    // return window.localStorage.getItem('user') ? JSON.parse(window.localStorage.getItem('user')) : new User();
  };
}

const instance = new jwtService();

export default instance;
