function setRoutes(config: any) {
  let routes = [...config.routes];

  if (config.settings || config.auth) {
    routes = routes.map((route) => {
      let auth = config.auth ? [...config.auth] : [];
      auth = route.auth ? [...auth, ...route.auth] : auth;
      return {
        ...route,
        settings: { ...config.settings, ...route.settings },
        auth,
      };
    });
  }

  return [...routes];
}

export function generateRoutesFromConfigs(configs: any[]) {
  let allRoutes: any = [];
  configs.forEach((config) => {
    allRoutes = [...allRoutes, ...setRoutes(config)];
  });
  return allRoutes;
}
