// import loadable from "@react-loadable/revised";
import loadable from '@loadable/component'

/**
 * Loadable defaults
 * For to Avoid Repetition
 */
const LoadableComponent = (opts: any) => {
  return loadable(opts.loader);
  //   Object.assign(
  //     {
  //       loading: (event: any) => {
  //         console.log("Loadeable Log Loading >>>>>", event)
  //         console.log("Loadeable Log Props >>>>>", opts)
  //         return <CustomLoader size="large"/>
  //       },
  //       delay: 300,
  //       timeout: 3000,
  //     },
  //     opts
  //   )
  // );
};

export default LoadableComponent;
