import { Button, ButtonProps, Spin } from "antd";
import { ReactNode } from "react";
import './custom-button-loader.less';

export const CustomButtonLoader = ({
  form,
  size,
  type,
  htmlType,
  className,
  btnText,
  isLoading,
  children,
  onClick,
  disabled,
  style,
  isBlock,
  isDanger
}: {
  form?: string;
  size: ButtonProps['size'];
  type: ButtonProps['type'];
  htmlType: ButtonProps['htmlType'];
  className?: string;
  btnText?: string | ReactNode;
  isLoading?: boolean
  children?: any;
  onClick?: any;
  disabled?: boolean;
  style?: any;
  isBlock?: boolean;
  isDanger?: boolean;
}) => {

  return <Button
    form={form}
    size={size}
    type={type}
    htmlType={htmlType}
    loading={isLoading}
    className={className ? className : ''}
    onClick={onClick ? onClick : null}
    disabled={disabled ? disabled : false}
    style={style}
    block={isBlock ? isBlock : false}
    danger={isDanger ? isDanger : false}
  >
    {btnText} {children}
  </Button>
}