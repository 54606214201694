import { Button, Typography } from "antd";

const ErrorAppCrashPage = () => {
  const renderRedirect = () => {
    return window.location.reload();
  };

  return (
    <div
      className="flex-col-center"
      style={{ backgroundColor: "#fafafa" }}
    >
      <div className="max-w-512 text-center">
        <Typography.Title level={5} className="mb-16" style={{ color: "rgba(0, 0, 0, 0.54"}}>Well, you broke the internet!</Typography.Title>

        <Typography className="mb-38" style={{ color: "rgba(0, 0, 0, 0.54"}}>
          Just kidding, looks like we have an internal issue, please try again
          in couple minutes
        </Typography>
        <Button
          danger
          size="large"
          className="font-medium"
          onClick={renderRedirect}
        >
          Try Reloading Page
        </Button>
      </div>
    </div>
  );
};

export default ErrorAppCrashPage;
