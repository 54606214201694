import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { AppUserType } from "../../constants/AppConstant";
import { ChildProps } from "../../constants/interface";
import jwtService from "../../services/jwtService";
import { updateUser } from "../../store/actions";

export default function Auth({ children }: ChildProps) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    jwtCheck();
  }, []);

  function jwtCheck() {
    jwtService.on("onAutoLogin", () => {
      // console.log("Loggin in with JWT.");
      jwtService
        .signInWithToken()
        .then((user) => {
          dispatch(updateUser(user));
          if (["/login", "/forgot-password"].includes(location.pathname)){
            user.userType === AppUserType.ADMIN ? navigate("/manage/vendors", { replace: true }) : user.userType === AppUserType.VENDOR ? navigate("/business-home", { replace: true }) : navigate("/profile", { replace: true });
          }
          else navigate(location, { replace: true  });
        })
        .catch(() => navigate("/login", { replace: true }));
    });

    jwtService.on("onAutoLogout", () => {
      navigate("/login", { replace: true });
      jwtService.logout();
    });

    jwtService.init();
  }

  return <React.Fragment>{children}</React.Fragment>;
}
