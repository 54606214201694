import "./App.less";
import { BrowserRouter as Router } from "react-router-dom";
import { BaseRouter } from "./components/BaseRouter";
import "antd/dist/antd.less";
import Auth from "./components/auth/Auth";
import Authorization from "./components/authorization/Authorization";
import { DefaultErrorBoundary } from "./components/error-boundry";
import { Provider } from "react-redux";
import { store } from "./store";

function App() {
  return (
    <div className="app">
      <Provider store={store}>
        <DefaultErrorBoundary>
          <Router>
            <Auth>
              <Authorization>
                <BaseRouter />
              </Authorization>
            </Auth>
          </Router>
        </DefaultErrorBoundary>
      </Provider>
    </div>
  );
}

export default App;
