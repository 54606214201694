import axios, { AxiosError, AxiosRequestConfig, AxiosResponse, ResponseType } from "axios";
import apiConsts from "../constants/apiConstants";
import { ErrorMsg } from "../constants/ErrorConstant";
export interface PayloadParams {
  [key: string]: string | number | object | boolean | null;
}

export interface ErrorResponseHandler {
  status: number,
  data: null,
  success: boolean,
  message: string,
  errors: any,
  totalRecord: number,
  messageCode: string,
}

const config: any = {
  'v2': apiConsts.serverURL,
  'v3': apiConsts.serverURLV3,
};

const getCustomHeaders = (bypass: boolean) => {
  const user = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")!) : undefined;
  if(!bypass || !user) {
    return undefined
  }
  if(user) {
    return {
      "assumed-business-mongodb-id": user.restaurantDetails || undefined,
      "assumed-business-role": user.userType 
    }
  }
};

const getBackendURL = (version = 'v2') => {
  if (version in config) {
    return config[version];
  } else {
    throw new Error(`Invalid backend version: ${version}`);
  }
};

export const network = async (
  url: string,
  payload?: PayloadParams,
  method: "GET" | "POST" | "PATCH" | "PUT" | "DELETE" = "GET",
  customHeaders?: {},
  version?: "v2" | "v3",
  bypass = false,
  responseType?: ResponseType
) => {
  // from env
  const apiURL = !bypass ? getBackendURL(version) + url : url;
  const configs: AxiosRequestConfig = {
    headers: {},
    params: {},
    responseType
  };

  //TOKEN SET IN HEADER
  const token = await localStorage.getItem("jwt_access_token");

  if (token) {
    configs.headers = {
      ...configs.headers,
      Authorization: "Bearer " + token,
      ...getCustomHeaders(bypass),
    };
  }

  if(customHeaders){
    configs.headers = {
      ...configs.headers,
      ...customHeaders,
    }
  }

  if (method === "GET") {
    configs.params = payload;
  }

  if (method === "DELETE" && payload) {
    configs.data = payload;
  }

  switch (method) {
    case "GET":
      try {
        const res: AxiosResponse = await axios.get(apiURL, configs);
        return responseHandler(res, bypass);
      } catch (err) {
        return errorHandler(err as AxiosError);
      }
    case "POST":
      try {
        const res: AxiosResponse = await axios.post(apiURL, payload, configs);
        return responseHandler(res, bypass);
      } catch (err) {
        return errorHandler(err as AxiosError);
      }
    case "PUT":
      try {
        const res: AxiosResponse = await axios.put(apiURL, payload, configs);
        return responseHandler(res, bypass);
      } catch (err) {
        return errorHandler(err as AxiosError);
      }
    case "PATCH":
      try {
        const res: AxiosResponse = await axios.patch(apiURL, payload, configs);
        return responseHandler(res, bypass);
      } catch (err) {
        return errorHandler(err as AxiosError);
      }
    case "DELETE":
      try {
        const res: AxiosResponse = await axios.delete(apiURL, configs);
        return responseHandler(res, bypass);
      } catch (err) {
        return errorHandler(err as AxiosError);
      }
  }
};

/**
 * @description This function is used for handling the API response.
 * @param {AxiosResponse} res
 * @returns
 */
export const responseHandler = (res: AxiosResponse, custom = false) => {
  if(!res) throw new Error("Network Err!");
  const response = {
    status: res.status,
    data: null,
    success: false,
    message: res.data?.message || ErrorMsg[res.data?.messageCode],
    errors: null,
    totalRecord: 0,
    messageCode: res.data.messageCode,
  };
  switch (res.status) {
    case 401:
      return {
        ...response,
        errors: res.data?.errors,
      };
    default:
      if (res.headers["content-type"] === "text/csv; charset=utf-8") {
        return {
          ...response,
          data: res,
          success: [200, 201].includes(res.status)
        }
      }
      return {
        ...response,
        data: !custom ? res.data.data : res.data,
        totalRecord: res.data.totalRecord,
        success: [200, 201].includes(res.status),
      };
  }
};

/**
 * @description This function is used for handling the API errors.
 * @param {AxiosError} err
 * @returns
 */
export const errorHandler = (err: AxiosError): ErrorResponseHandler  => {
  if(err.response){
    return {
      status: err.response.status,
      data: err?.response?.data?.data || null,
      message: ErrorMsg[err.response.data.messageCode] || err.response.data.message,
      errors: (err as any).response.data.errors,
      success: false,
      messageCode: err.response.data.messageCode,
      totalRecord: 0,
    }
  }
  return {
    status: 500,
    data: null,
    message: err.message,
    errors: (err as any).errors,
    success: false,
    messageCode: "",
    totalRecord: 0,
  };
};
