import { notification } from "antd";
import { IconType } from "antd/lib/notification";

export const notify = (
  type: IconType = "info",
  message: string,
  description?: string,
  duration = 3,
) => {
  const notificationArgs = {
    message: message,
    description: description,
    duration: duration,
    type: type,
  };
  notification.open(notificationArgs);
};
