import React, { useEffect, useState } from "react";
import { matchRoutes, useLocation, useNavigate, useParams } from "react-router-dom";
import { adminRoutes, managerRoutes, vendorRoutes, externalRoutes } from "../../configs/RoutesConfig";
import { AppUserType } from "../../constants/AppConstant";
import { ChildProps, routeMatch } from "../../constants/interface";
import jwtService from "../../services/jwtService";

function Authorization({ children }: ChildProps) {
  const [accessGranted, setAccessGranted] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();
  const { pathname } = location;


  useEffect(() => {
    if (!accessGranted) {
      redirectRoute();
    }
  }, [accessGranted]);

  useEffect(() => {
    const user = jwtService.getCurrentUser();
    let routes = adminRoutes;
    if (user && user.userType === AppUserType.VENDOR) routes = vendorRoutes;
    if (user && user.userType === AppUserType.MANAGER) routes = managerRoutes;
    if (user && user.userType === AppUserType.EXTERNAL) routes = externalRoutes;
    
    const matched = (
      matchRoutes(routes, pathname) ? matchRoutes(routes, pathname)![0] : null
    ) as routeMatch;

    const accessGrantedNew =
      matched &&
        matched.route &&
        matched.route.auth &&
        matched.route.auth.length > 0 &&
        user &&
        user.userType
        ? matched.route.auth.includes(user.userType)
        : true;
    setAccessGranted(accessGrantedNew);
  }, [location]);

  function redirectRoute() {
    const user = jwtService.getCurrentUser();
    if(user)
      user.userType === AppUserType.ADMIN ? navigate('/manage/vendors', { replace: true}) : user.userType === AppUserType.VENDOR ? navigate('/business-home', { replace: true})  :  navigate('/profile', { replace: true});
  }

  return accessGranted ? (
    <React.Fragment>{children}</React.Fragment>
  ) : null;
}

export default Authorization;
