import LoadableComponent from "../components/loadable-component/LoadableComponent";
import authRoles from "../constants/authConstants";
import { CommonRouteConfig } from "./CommonRoutes";

export const ManagerPageConfig = {
  auth: authRoles.vendor,
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: '/profile',
      component: LoadableComponent({
        loader: () => import("../main/manager-profile"),
      }),
    },
    ...CommonRouteConfig
  ],
};
