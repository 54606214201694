/**
 * Authorization Roles
 */
const authRoles = {
  admin: ["ADMIN"],
  vendor: ["VENDOR", "MANAGER"],
  manager: ["MANAGER"], 
  external: ["EXTERNAL"],
};

export default authRoles;
