import React, { Component, ReactNode } from "react";
import ErrorAppCrashPage from "../errors/ErrorAppCrashPage";

interface Props {
  children: ReactNode;
}

interface State {
  hasError: boolean;
}

class DefaultErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error: Error, info: any) {
    // Display fallback UI
    this.setState({ hasError: true });
    // You can also log the error to an error reporting service
  }

  render() {
    const { children } = this.props;

    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <ErrorAppCrashPage />;
    }
    return <React.Fragment>{children}</React.Fragment>;
  }
}

export default DefaultErrorBoundary;
