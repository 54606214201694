import LoadableComponent from "../components/loadable-component/LoadableComponent";
import authRoles from "../constants/authConstants";
import { CommonRouteConfig } from "./CommonRoutes";

export const VendorPageConfig = {
  auth: authRoles.vendor,
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: '/business-home',
      component: LoadableComponent({
        loader: () => import("../main/vendor-main-profile-navigation"),
      }),
    },
    {
      path: '/profile',
      component: LoadableComponent({
        loader: () => import("../main/vendor-main-profile"),
      }),
    },
    {
      path: '/vendors/:vendorId/payment-link',
      component: LoadableComponent({
        loader: () => import("../main/payment-links/index"),
      }),
    },
    {
      path: '/vendors/:vendorId/payment-link/add',
      component: LoadableComponent({
        loader: () => import("../main/payment-links/add-edit-payment-link/index"),
      }),
    },
    ...CommonRouteConfig
  ],
};
