import { Result, Button } from "antd";
import { StyledLink } from "../utils/StyledLink";
import { useSelector } from "react-redux";
import { AppState, User } from "../../constants/interface";
const Page404 = () => {
  const userRedux: User | null = useSelector((state: AppState) => state.user);
  const role: string | undefined = userRedux?.userType;
  const link = (role === "ADMIN" ? "/manage/vendors" : (role === "VENDOR" || role === "MANAGER" ? "/profile" : ""));
  return (
    <Result
      status="404"
      title="404"
      subTitle="Sorry, the page you visited does not exist."
      extra={<Button type="primary"><StyledLink to={link}>Back Home</StyledLink></Button>}
    />
  );
};

export default Page404;