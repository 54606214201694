import { Routes, Route, Navigate } from "react-router-dom";
import Login from "./login";
import ForgotPassword from "./login/ForgotPassword";
import { useSelector } from "react-redux";
import { AppState } from "../constants/interface";
import {adminRoutes, externalRoutes, managerRoutes, vendorRoutes} from "../configs/RoutesConfig";
import Page404 from "./page404/Page404";
import LayoutComponent from "./layout/layout";
import { AppUserType } from "../constants/AppConstant";
import UnauthorizedAccess from "./unauthorized-access";

export const BaseRouter: React.FC = () => {
  const user = useSelector((state: AppState) => state.user);
  let routes = adminRoutes;
  if(user && user.userType === AppUserType.VENDOR) routes = vendorRoutes;
  if(user && user.userType === AppUserType.MANAGER) routes = managerRoutes;
  if(user && user.userType === AppUserType.EXTERNAL) routes = externalRoutes;
  
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      {routes.map((route) => {
        return (
          <Route
            path={route.path}
            element={
              <LayoutComponent>
                <route.component />
              </LayoutComponent>
            }
            key={route.path}
          />
        );
      })}
      <Route
        path="/"
        element={<Navigate replace to={user ? user.userType === AppUserType.ADMIN ? "/manage/vendors": user.userType === AppUserType.VENDOR ? "/business-home" :  "/profile" : "/login"} />}
      />
      <Route path="/404" element={<Page404 />} />
      <Route path="/unauthorized-access" element={<UnauthorizedAccess/>}/>
      <Route path="*" element={<Navigate replace to="/404" />} />
    </Routes>
  );
};
