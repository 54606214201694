import { network } from "../common/network";
import { notify } from "../common/notify";
import apiConsts from "../constants/apiConstants";

class VendorService {
  public async getAll(params: any) {
    let query: any = {
      page: params.paginate.current - 1,
      rowsPerPage: params.paginate.pageSize,
      searchText: params.searchText,
      isArchived: params.isArchived,
      characterFilter: params.characterFilter,
      sortBy: params.sorter ? {
        [params.sorter.columnKey]: params.sorter.order === "ascend" ? 1 
        : params.sorter.order === "descend" ? -1 
        : params.sorter.order
      } : {}
    };
    if(params.filters && params.filters.status) {
      query.status = JSON.stringify(params.filters.status);
    }
    const response:any = await network(apiConsts.vendorURI, query);
    return {
      data: response?.data,
      totalRecord: response?.totalRecord,
    };
  }
  public async archiveVendor(vendorId: string) {
    const uri = `${apiConsts.vendorURI}/archive/${vendorId}`;
    const response = await network(uri, undefined, "DELETE");
    if (response?.success) {
      notify(
        "success",
        "Selected vendor archived."
      );
      return response;
    }
    notify("error", response?.message);
    return;
  }
  public async unarchiveVendor(vendorId: string) {
    const uri = `${apiConsts.vendorURI}/unarchive/${vendorId}`;
    const response = await network(uri, undefined, "DELETE");
    if (response?.success) {
      notify(
        "success",
        "Selected vendor unarchived."
      );
      return response;
    }
    notify("error", response?.message);
    return;
  }
  public async removeVendor(vendorId: string) {
    const uri = `${apiConsts.vendorURI}/${vendorId}`;
    const response = await network(uri, undefined, "DELETE");
    if (response?.success) {
      notify(
        "success",
        "Selected vendor removed. This action cannot be undone!"
      );
      return response;
    }
    notify("error", response?.message);
    return;
  }
  public async getOne(vendorId: string) {
    const response = await network(
      `${apiConsts.vendorURI}/${vendorId}`,
      {},
      "GET"
    );
    if(response?.success){
      return response?.data;
    }
    throw response;
  }
  public async create(newVendor: any) {
    const response = await network(
      `${apiConsts.vendorURI}`,
      newVendor,
      "POST"
    );
    if (response?.success) {
      notify(
        "success",
        "Vendor created successfully."
      );
      return response;
    }
    notify("error", response?.message);
    throw response;
  }
  public async update(vendorId: string, newVendor: any) {
    const response = await network(
      `${apiConsts.vendorURI}/${vendorId}`,
      newVendor,
      "PUT"
    );
    if (response?.success) {
      notify("success", "Vendor updated successfully!");
      return response;
    }
    notify("error", response?.message);
    throw response;
  }
  public async changePassword(
    oldPassword: string,
    newPassword: string,
    confirmNewPwd: string,
    vendorId: string
  ) {
    const response = await network(
      `${apiConsts.vendorURI}/${vendorId}`,
      {},
      "PUT"
    );
    if (response?.success) {
      notify(
        "success",
        "Password changed successfully."
      );
    }
    else {
      notify(
        "error",
        "Password changed failed."
      );
    }
    return
  }

  public async getUnreadNotificationCount(params:any){
    const uri = `/v2/notification-v2/unread-count`;
    const response = await network(uri, params, "GET");
    if(response?.success){
      return response?.data
    }
    notify("error", response?.message);
    return
  }

  public async getVendorNotifications(params: any){
    const query: any = {
      rowsPerPage: params.paginate.pageSize,
      page: params.paginate.current - 1,
      vendorId: params.vendorId,
      markAsRead: params.markAsRead || false
    }
    const uri = `/v2/notification-v2/list`;
    const response = await network(uri, query, "GET");
    if(response?.success){
      return {
        data: response?.data,
        totalRecord: response?.totalRecord
      }
    }
    notify("error", response?.message);
    return {
      data: [],
      totalRecord: 0
    }
  }

  public async markNotificationAsRead(notificationId: string){
    const uri = `/v2/notification-v2/${notificationId}/marked-as-read`;
    const response = await network(uri, {}, "PUT");
    if(response?.success){
      return;
    }
    notify("error", response?.message);
    return;
  }

  public async getAssignedTerminals(vendorId: string, params: any){
    const uri = `${apiConsts.vendorURI}/${vendorId}/adyen-deivce-reassignment/terminals`;
    const response = await network(uri, params, "GET");
    if(response?.success){
      return response.data;
    }
    notify("error", response?.message);
    return;
  }

  public async migrateToMicroservice(vendorId: string){
    const uri =  `${apiConsts.vendorOutletURI}/${vendorId}/migrate`;
    const response = await network(uri, {}, "PUT");
    if(response?.success){
      return response.data;
    }
    notify("error", response?.message);
    return;
  }

  public async voucherItemDetails(vendorId: string, stores: string[]) {
    const response = await network(
      `${apiConsts.vendorV3URI}/${vendorId}/pms/items/voucher-item-details`,
      { stores },
      "POST",
      undefined,
      "v3"
    );
    if (response.success) {
      return response.data;
    }
    throw response;
  }

  public async voucherCategoryItems(vendorId: string, stores: string[]) {
    const response = await network(
      `${apiConsts.vendorV3URI}/${vendorId}/pms/items/voucher-item-category-details`,
      { stores },
      "POST",
      undefined,
      "v3"
    );
    if (response.success) {
      return response.data;
    }
    throw response;
  }
}

export const vendorService = new VendorService();
