import { generateRoutesFromConfigs } from "../components/utils/RoutesConfigUtils";
import { UserPageConfig } from ".";
import { AdminPageConfig } from "./AdminRoutes";
import { VendorPageConfig } from "./VendorRoutes";
import { ManagerPageConfig } from "./ManagerRoutes";
import { ExternalPageConfig } from "./ExternalRoutes";

const routeConfigs = [
  ...UserPageConfig,
];

const routes = [
  ...generateRoutesFromConfigs(routeConfigs),
];

const adminRoutes = [
  ...generateRoutesFromConfigs([AdminPageConfig]),
];
const vendorRoutes = [
  ...generateRoutesFromConfigs([VendorPageConfig]),
];
const managerRoutes = [
  ...generateRoutesFromConfigs([ManagerPageConfig]),
];
const externalRoutes = [
  ...generateRoutesFromConfigs([ExternalPageConfig]),
]
export default routes;

export {
  adminRoutes,
  vendorRoutes,
  managerRoutes,
  externalRoutes
}
