const apiConsts = {
  portalURL: process.env.REACT_APP_PORTAL_URL,
  serverURL: process.env.REACT_APP_SERVER_URL,
  serverURLV3: process.env.REACT_APP_SERVER_URL_V3,
  ioURL: process.env.REACT_APP_IO_URL,
  printerURL: process.env.REACT_APP_PRINTER_URL,
  imageURL: process.env.REACT_APP_IMAGE_URL,
  placesApiKey: process.env.REACT_APP_PLACES_API_KEY,
  zendeskKey: process.env.REACT_APP_ZENDESK_KEY,
  isSquareEnabled: process.env.REACT_APP_SQUARE_ENABLED === "true",
  socketURL: process.env.REACT_APP_SOCKET_URL,
  kioskURL: process.env.REACT_APP_KIOSK_URL,
  eventURI: "/events",
  venueURI: "/venues",
  vendorURI: "/v2/vendors",
  itemURI: "/v1/menu-items",
  categoryURI: "/categories",
  mainCategoryURI: "/main-categories",
  tagURI: "/tags",
  vendorZoneURI: "/vendor-zones",
  authURI: "/v1/users",
  userURI: "/v2/users",
  groupURI: "/groups",
  customerURL: process.env.REACT_APP_CUSTOMER_SERVICE_URL,
  customerURI: '/customers',
  voucherURL: process.env.REACT_APP_VOUCHER_SERVICE_URL,
  voucherURI: '/vouchers',
  orderURI: "/orders",
  bookingURI: "/bookings",
  feedbackURI: "/feedback",
  promoCodeURI: "/promos",
  faqURI: "/v2/faq",
  reportURI: "/reports",
  reportsURI: "/v2/report",
  payoutReport: "/v2/payout-report",
  invitedBusinessReportUri: "/v2/invited-business-report",
  dashboardURI: "/dashboard",
  segmentURI: "/v2/segment",
  squareURI: "/square",
  qrcodeURI: "/v2/qrcode",
  toppingURI: "/topping",
  inpersonOrderURI: "/inperson-orders",
  areaURI: "/areas",
  employeeURI: "/employee",
  floorURI: "/floor",
  terminalPaymentURI: "/terminal-payment",
  vendorOutletURI: "/v2/vendors",
  outletV2URI: '/v2/outlet',
  outletURI: '/outlet',
  notifications: '/v2/notification',
  ingredientURI: '/ingredient',
  paymentLinkURI: '/payment-link',
  modifierURI: '/modifier',
  menuURI: '/menu',
  merchantURI: '/merchant-service-account',
  balanceAccountURI: '/balance-account',
  promotionURI:'/promotion',
  _promotionURL: process.env.REACT_APP_PROMOTION_SERVICE_URL,
  _promotionURI: '/promotions',
  emailReceiptURI: '/email-receipt',
  feedback: '/feedback',
  adyenDeviceURI: '/adyen-deivce-reassignment',
  otherDeviceURI: '/device-info',
  stockActionURI: '/stock-action',
  siteMembershipsURI:'/site-memberships',
  downloadCSV: '/download-csv',
  taxesURI: '/pms/taxes',
  vendorV3URI: "/v3/vendors",
  modifiers: '/pms/modifiers',
  ingredientPmsURI: '/pms/ingredients',
  ingredientCategoryPmsUri: '/pms/ingredients-category',
  DEV: process.env.REACT_APP_DEV === "true",
  ADDRESS_ALLOWED_COUNTRIES: process.env.REACT_APP_ADDRESS_ALLOWED_COUNTRIES?.split(','),
  pmsStockActionsURI: '/pms/stock-action',
  pmsPurchaseUnitURI: '/pms/purchase-unit',
  paymentInvoiceURI: '/payment-invoice'
};

export default apiConsts;
