export const AppConstant = {
    deleteConfirmMsg: 'Are you sure you want to delete this record?',
    deleteAllConfirmMsg: 'Are you sure you want to delete all this record?',
    archiveConfirmMsg: 'Are you sure you want to archive this record?',
    unarchiveConfirmMsg: 'Are you sure you want to unarchive this record?',
    fileAvaiableInCustomCsv: 'The downloaded file will be accessible within the "Custom CSV" list located in the report section.',
    changesAppliedToAllWarehouse: 'This change will be applied to all stock locations.',
    itemTaxInfo: 'Taxes configured to apply to all items are automatically selected to new items. Edit Tax application rules in the Taxes section',
    itemCustomAttributeInfo: 'Custom attribute includes details related to allergens and dietary. Create or edit your custom attribute',
    itemModifierGroupInfo: 'Select modifier group to apply to this item. Create new in modifier list Here or manage existing in modifier groups',
}


export enum AppUserType {
    VENDOR='VENDOR',
    ADMIN='ADMIN',
    MANAGER='MANAGER',
    EXTERNAL='EXTERNAL'
}

export const BulkUploadStatusLabel: any = {
    "FAILED" : "Failed", 
    "IN PROGRESS" : "In progress",
    "COMPLETED" :"Completed", 
    "PARTIALLY_COMPLETED": "Partial Success"
};

export const BulkUploadReasonLabel: any = {
    "INVALID_HEADERS": "Invalid Headers",
    "EMPTY_FILE": "Empty File",
    "DATA_MISSING": "Data Missing",
    "DB_ERROR": "N/A",
    "N/A": "N/A"
};

export const ADYEN_SALES_CHANNEL =  {
    "ECOMMERCE": "eCommerce",
    "POS": "pos"
}


export const USA_TAX_LABELS = {
    "stateTax": "State Tax",
    "countyTax": "County Tax",
    "localTax": "Local Tax"
}
export const CAD_TAX_LABELS = {
    "GST": "GST",
    "PST": "PST",
    "HST": "HST"
}
export const AUS_AND_NZ_TAX_LABELS = {
  "GST": "GST",
}
export const GERMANY_TAX_LABELS = {
  "VAT": "VAT",
}

export const VAT_TAX_LABELS = {
    "VAT":  "VAT on Sales"
}

export const TAX_OPTIONS = [
    {
      label: "Yes",
      value: true,
    },
    {
      label: "No",
      value: false,
    },
  ];
  
export const TAX_LOCATION_OPTIONS = [
    {
      label: "All locations",
      value: "ALL_LLOCATIONS",
    },
    {
      label: "Selected locations",
      value: "SELECTED_LOCATIONS",
    },
    {
      label: "None",
      value: "NONE",
    },
  ];

export const countriesList = [
  { value: "GB", label: "United Kingdom" },
  { value: "US", label: "United States" },
  { value: "CA", label: "Canada" },
  { value: "IT", label: "Italy" },
  { value: "FR", label: "France" },
  { value: "AU", label: "Australia" },
  // { value: "NZ", label: "New Zealand" },
  { value: "ES", label: "Spain" },
  { value: "DE", label: "Germany" },
];

export enum PaymentInvoiceType {
  FEES='FEES',
  OTHER_EXPENSES='OTHER-EXPENSES'
}

export const MONTHS = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const SPLIT_VENDOR_TYPE = {
  SITE_OWNER: "SITE_OWNER",
  SITE_TRADER: "SITE_TRADER",
};

export const adyenFeeTypes = [
  { label: "Blended Rate", value: "blended" },
  { label: "Interchange", value: "interchange" },
];

export const cardSchemeLabels: any = {
  amex: "American Express",
  discover: "Discover",
  maestro: "Maestro",
  mc: "Mastercard",
  visa: "Visa",
  maestrouk: "Maestro",
};

export const SPLIT_TYPES: any = {
  splitOnly: "SPLIT_ONLY"
};

export const COMMISION_VAT_OPTIONS = [
  {label: "Exclusive", value: "EXCLUSIVE"},
  {label: "Inclusive", value: "INCLUSIVE"}
]

export const PAYMENT_FEE = [
  {label: "Site", value: SPLIT_VENDOR_TYPE.SITE_OWNER },
  {label: "Store", value: SPLIT_VENDOR_TYPE.SITE_TRADER }
]
export enum TAB_TYPES {
  SALES_SUMMARY = "SALES SUMMARY",
  OTHER_SUMMARY = "OTHER SUMMARY",
  SHIFT_REPORT = "SHIFT REPORT",
  REVENUE_SHARE = "REVENUE SHARE",
}

export const VAT_TYPE_MAP:any = {
  EXCLUSIVE: "Exclusive",
  INCLUSIVE: "Inclusive",
}

export const CHARGE_TYPE_MAP:any = {
  "DEBIT": "Debit",
  "CREDIT": "Credit",
}

export const discountType: any = {
  PERCENTAGE: "Percentage",
  NUMBER: "Number"
}

export const voucherDiscountType: any = {
  FIXED: 'Fixed',
  PERCENTAGE_DISCOUNT: 'Percentage',
  FREE: 'Free',
};


export const ADYEN_DEVICE_LAST_ACTIVITY_BADGE: any = {
  "RED": "#ff0000",
  "GREEN": "#008000",
  "ORANGE": "#ffa500",
};