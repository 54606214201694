import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { navConfigChildParams } from "../types";
import { AppState, User } from "../../constants/interface";
import navigationConfig, { buildConfigArray, setPermissionByUser } from "./config";
import { Menu } from "antd";
import { HomeOutlined, LogoutOutlined, ShopOutlined, TagFilled } from "@ant-design/icons";
import { StyledLink } from "../utils/StyledLink";
import logo from "../../assets/images/noq_header2_logo.png";
import "../../App.less";
import jwtService from "../../services/jwtService";
import { getVendorDetails, updateUser } from "../../store/actions";
import { useLocation, useNavigate } from "react-router-dom";
import { AppUserType } from "../../constants/AppConstant";
import { vendorService } from "../../services/VendorService";
import { sitesService } from "../../services/SitesService";
import { notify } from "../../common/notify";

export const Sidebar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userRedux: User | null = useSelector((state: AppState) => state.user);
  const vendorRedux = useSelector((state:AppState) => state?.vendor?.vendorDetails);
  const [permissions, setPermissions] = useState<any>([]);
  // lets say Im getting auth role from redux
  const role: string | undefined = userRedux?.userType;
  const [vendorDetails, setVendorDetails] = useState<any>(vendorRedux);
  const [isInvitedStoreExistsForSites, setIsInvitedStoreExistsForSites] = useState<any>(false);
  const config = buildConfigArray(vendorDetails, role === AppUserType.ADMIN, role, { isInvitedStoreExistsForSites: isInvitedStoreExistsForSites }, permissions);
  const [sidebarConfig, setSidebarConfig] = useState(config);
  const [openKeys, setOpenKeys] = useState<any>([]);
  const [selectedMenuItemKey, setSelectedMenuItemKey] = useState<any>();
  const { pathname } = useLocation();

  useEffect(() => {
    if(userRedux?.restaurantDetails){
      Promise.all([
        vendorService.getOne(String(userRedux?.restaurantDetails)),
        sitesService.checkIfExternalStoresAvailableInAnySite(String(userRedux?.restaurantDetails))
      ])
      .then(([vendorRes, externalBusinessResponse]) => {
        if(vendorRes){
          setVendorDetails(vendorRes)
        }
        if(externalBusinessResponse){
          setIsInvitedStoreExistsForSites(externalBusinessResponse.available)
        }
      }).catch(err => {
        notify("error", "Business not found");
      });
    }
  },[userRedux?.restaurantDetails])

  useEffect(() => {
   const selectedItem = selected();
    let selectedItemId = "";
    if(selectedItem && selectedItem?.id){
      selectedItemId = selectedItem?.id;
    }else{
      selectedItemId = role === AppUserType.ADMIN
      ? "businesses"
      : role === AppUserType.VENDOR
      ? "business-home"
      : "profile";
    }
    setSelectedMenuItemKey(selectedItemId);
    const keysToOpen = getSubMenuKeysToOpen(selectedItemId);
    if(keysToOpen){
      setOpenKeys(keysToOpen);
    }
    setPermissionByUser(String(vendorRedux?._id), userRedux?.userType === 'MANAGER' ? String(userRedux?.employeeRef) : null, setPermissions);
  },[pathname])

  useEffect(() => {
    const config = buildConfigArray(
      vendorDetails,
      role === AppUserType.ADMIN,
      role,
      { isInvitedStoreExistsForSites: isInvitedStoreExistsForSites },
      permissions,
    );
    setSidebarConfig(config);
  },[
    vendorDetails?.isMicroserviceActive, 
    vendorDetails?.isPaymentLinkEnabled, 
    vendorDetails?.isMerchandiseEnabled,
    vendorDetails?.isStockLocations,
    vendorDetails?.isStockManagementActive,
    isInvitedStoreExistsForSites,
    permissions
  ]);

  const getSubMenuKeysToOpen = (selectedItemId: any): Array<any> | undefined => {
    const findKeys = (item: Array<any>, submenuKeys: Array<any>): Array<any> | undefined => {
      for(const child of item){
        const currentKeys = [...submenuKeys];
        if(child?.children?.length){
          currentKeys.push(child.id);
          const childItemHasChildren = child.children.filter((child: any) => child.children && child.children.length);
          const childItemHasNoChildren = child.children.filter((child: any) => !child.children || (child.children && !child.children.length));
          if(childItemHasNoChildren?.length){
            const foundSelectedItem = childItemHasNoChildren.find((childMenuItem: any) => childMenuItem.id === selectedItemId);
            if (foundSelectedItem) {
              return currentKeys;
            } else if (childItemHasChildren?.length) {
              const result = findKeys(childItemHasChildren, currentKeys);
              if (result) {
                return result;
              }
            }
          }else if (childItemHasChildren?.length) {
            const result = findKeys(childItemHasChildren, currentKeys);
            if (result) {
              return result;
            }
          }
        }else {
          return child.id === selectedItemId ? currentKeys : undefined;
        };
      }
      return undefined;
    }

    if(sidebarConfig?.length){
      for (const config of sidebarConfig) {
        if (config?.children?.length) { 
          const submenuKeys = [config.id]; 
          const result = findKeys(config.children, submenuKeys);
          if (result) {
            return result;
          }
        }else{
          continue
        }
      }
      return undefined;
    }
  }

  const selected = () => {
    let selectedChild:any = {};
    sidebarConfig?.forEach((item:any) => {
      if(item?.type === "heading") return;
      if(item?.children && item.children.length){
        item?.children.forEach((subItem:any) => {
          if(subItem.children && subItem.children.length){
            subItem.children.forEach((childItem:any) => {
              const path = childItem.path.split('/');
              if (childItem.child && !pathname.includes(path[path.length - 1])) {
                return childItem.child.find((c: string) => pathname.includes(c))
              }
              if(pathname.includes(path[path.length - 1])) selectedChild = childItem;
            })
          }else{
            const path = subItem.path.split('/');
            if (subItem.child && !pathname.includes(path[path.length - 1])) {
              return subItem.child.find((c: string) => pathname.includes(c))
            }
            if(pathname.includes(path[path.length - 1])) selectedChild = subItem;
          }
        })
      }else{
        const path = item.path.split('/');
        if (item.child && !pathname.includes(path[path.length - 1])) {
          return item.child.find((c: string) => pathname.includes(c))
        }
        if(pathname.includes(path[path.length - 1])) selectedChild = item;
      }
    })
    return selectedChild;
  }

  const getPath = (path: string) => {
    if (userRedux && userRedux.userType === AppUserType.ADMIN) return path;
    if (userRedux && (userRedux.userType === AppUserType.VENDOR || userRedux.userType === AppUserType.MANAGER || userRedux.userType === AppUserType.EXTERNAL)) return path?.replace(":vendorId", userRedux?.restaurantDetails);
    return path;
  }

  const getSelected = () => {
    const selectedKey = selected();
    return [selectedKey?.id || "profile"];
  }

  const renderMenuItems = (menuItems: any) => {
    return menuItems.map((menuItem: any) => {
      if (menuItem.children && menuItem.children.length > 0) {
        const isSubMenuHasMenuItems = menuItem.children.some((child: any) => child.path && child.path.length > 0 && child.id === selectedMenuItemKey);
        return (
          <Menu.SubMenu
            key={menuItem.id}
            title={menuItem.label}
            icon={menuItem.icon && React.createElement(menuItem.icon)}
            className={`${openKeys?.includes(menuItem?.id)  ? "active-submenu" : ""} ${isSubMenuHasMenuItems ? "sub-menu-with-selected-item" : ""}`}
          >
            {renderMenuItems(menuItem.children)}
          </Menu.SubMenu>
        );
      } else {
        return (
          <Menu.Item key={menuItem.id} icon={menuItem.icon && React.createElement(menuItem.icon)}>
            <StyledLink to={getPath(menuItem.path)}>{menuItem.label}</StyledLink>
          </Menu.Item>
        );
      }
    });

  }

  const handleOpenChange = (keys:any) => {
    const latestOpenKey = keys.find((key: any) => openKeys.indexOf(key) === -1);
    if (sidebarConfig?.some(item => item.id === latestOpenKey)) {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    } else {
      setOpenKeys(keys);
    }
  }

  const handleClickMenu = ({ key }: any) => {
    if(sidebarConfig){
      const rootLevelMenuItems = sidebarConfig.filter((child:any) => {
        return child.id && child.path && child.path.length > 0;
      })
      if(rootLevelMenuItems.findIndex((menuItem:any) => menuItem.id === key) > -1){
        setOpenKeys([])
      }
    }
  }

  return (
    <div className="w-100 sidebar-wrapper">
      <Menu 
        mode="inline"
        selectedKeys={getSelected()}
        onOpenChange={handleOpenChange}
        openKeys={openKeys}
        onClick={handleClickMenu}
        onSelect={({ key }) => setSelectedMenuItemKey(key)}
      >
        {sidebarConfig?.map((menuItem: navConfigChildParams) => {
          if(menuItem?.type === "heading") {
            return (
              <Menu.Item 
                disabled 
                key={menuItem?.id} 
                style={{ cursor: 'default', pointerEvents: 'none',fontFamily: "Europa Bold" }}
              >
                {menuItem?.label?.toUpperCase()}
              </Menu.Item>)
          }else if(menuItem?.children && menuItem.children.length){
            const isSubMenuHasMenuItems = menuItem.children.some((child: any) => child.path && child.path.length > 0 && child.id === selectedMenuItemKey);
            return (
              <Menu.SubMenu 
                key={menuItem.id} 
                title={menuItem.label} 
                icon={menuItem?.icon && React.createElement(menuItem?.icon)} 
                className={`
                  ${openKeys?.includes(menuItem?.id) ? "active-submenu" : ""} 
                  ${!openKeys.includes(menuItem.id) ? "highlightedSubMenu" : ""} 
                  ${isSubMenuHasMenuItems ? "sub-menu-with-selected-item" : ""}
                `}
              >
                {renderMenuItems(menuItem?.children)}
              </Menu.SubMenu>
            )
          }else{
            return (
              <Menu.Item key={menuItem.id} icon={menuItem.icon && React.createElement(menuItem.icon)}>
                <StyledLink to={getPath(menuItem.path)}>{menuItem.label}</StyledLink>
              </Menu.Item>
            )
          }
        })}
      </Menu>
    </div>
  );
};
