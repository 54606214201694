import { Result, Button } from "antd";
import { StyledLink } from "../utils/StyledLink";
import { useSelector } from "react-redux";
import { AppState, User } from "../../constants/interface";

const UnauthorizedAccess = () => {
  const userRedux: User | null = useSelector((state: AppState) => state.user);
  const role: string | undefined = userRedux?.userType;
  const link = (role === "ADMIN" ? "/manage/vendors" : (role === "VENDOR" ? "/business-home" : role === "MANAGER" ? "/profile" : ""));
  return (
    <Result
      status="403"
      title="403"
      subTitle="Sorry, the page you visited does not have access."
      extra={<Button type="primary"><StyledLink to={link}>Back Home</StyledLink></Button>}
    />
  );
};

export default UnauthorizedAccess;