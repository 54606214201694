import LoadableComponent from "../components/loadable-component/LoadableComponent";
import authRoles from "../constants/authConstants";

export const AdminPageConfig = {
  auth: authRoles.admin,
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: "/manage/admins",
      component: LoadableComponent({
        loader: () => import("../main/admin-list"),
      }),
    },
    {
      path: "/manage/admins/edit/:adminId",
      component: LoadableComponent({
        loader: () => import("../main/add-edit-admin"),
      }),
    },
    {
      path: "/manage/admins/add",
      component: LoadableComponent({
        loader: () => import("../main/add-edit-admin"),
      }),
    },
    {
      path: "/profile-admin",
      component: LoadableComponent({
        loader: () => import("../main/profile"),
      }),
    },
    {
      path: "/vendors/:vendorId/outlets/:outletId/integration",
      component: LoadableComponent({
        loader: () => import("../main/integration"),
      }),
    },
    {
      path: "/vendors/:vendorId/outlets/:outletId/printers",
      component: LoadableComponent({
        loader: () => import("../main/printer-list"),
      }),
    },
    {
      path: "/vendors/:vendorId/outlets/:outletId/branding",
      component: LoadableComponent({
        loader: () => import("../main/branding/index"),
      }),
    },
    {
      path: "/vendors/:vendorId/outlets/:outletId/assigned-promotions",
      component: LoadableComponent({
        loader: () => import("../main/assign-store-promotions"),
      }),
    },
    {
      path: "/vendors/:vendorId/outlets/:outletId/assigned-promotions/:promotionId/assign-items",
      component: LoadableComponent({
        loader: () => import("../main/assign-items-to-promotion"),
      }),
    },
    {
      path: "/vendors/:vendorId/outlets/:outletId/assigned-promotions/:promotionId/view-all-items",
      component: LoadableComponent({
        loader: () => import("../main/view-all-assigned-item-promotion"),
      }),
    },
    {
      path: "/vendors/:vendorId/outlets/:outletId/tidypay-settings",
      component: LoadableComponent({
        loader: () => import("../main/add-tidypay-settings"),
      }),
    },
    {
      path: "/vendors/:vendorId/merchant-service-account/:accountId/balance-accounts/:balanceAccountId/adyen-settings",
      component: LoadableComponent({
        loader: () => import("../main/adyen-payout-settings"),
      }),
    },
    {
      path: "/manage/faqs",
      component: LoadableComponent({
        loader: () => import("../main/faq-list"),
      }),
    },
    {
      path: "/faq/add",
      component: LoadableComponent({
        loader: () => import("../main/add-edit-faq"),
      }),
    },
    {
      path: "/faq/edit/:faqId",
      component: LoadableComponent({
        loader: () => import("../main/add-edit-faq"),
      }),
    },
    {
      path: "/manage/segment",
      component: LoadableComponent({
        loader: () => import("../main/segment-list"),
      }),
    },
    {
      path: "/manage/segment/add",
      component: LoadableComponent({
        loader: () => import("../main/add-edit-segment"),
      }),
    },
    {
      path: "/manage/segment/edit/:segmentId",
      component: LoadableComponent({
        loader: () => import("../main/add-edit-segment"),
      }),
    },
    {
      path: "/manage/qrcodes",
      component: LoadableComponent({
        loader: () => import("../main/qr-codes"),
      }),
    },
    {
      path: "/qrcodes/add",
      component: LoadableComponent({
        loader: () => import("../main/qr-codes/qr-code-add-edit"),
      }),
    },
    {
      path: "/qrcodes/edit/:qrcodeId",
      component: LoadableComponent({
        loader: () => import("../main/qr-codes/qr-code-add-edit"),
      }),
    },
    {
      path: "/manage/vendors",
      component: LoadableComponent({
        loader: () => import("../main/vendor-list"),
      }),
    },
    {
      path: "/vendors/:restaurantId/users/staff/login",
      component: LoadableComponent({
        loader: () => import("../main/staff"),
      }),
    },
    {
      path: "/vendors/:vendorId/outlets/:outletId/assign-staff",
      component: LoadableComponent({
        loader: () => import("../main/assign-staff"),
      }),
    },
    {
      path: "/vendors/:vendorId/outlets",
      component: LoadableComponent({
        loader: () => import("../main/outlets-list"),
      }),
    },
    {
      path: "/vendors/:vendorId/products",
      component: LoadableComponent({
        loader: () => import("../main/products-edit-navigation"),
      }),
    },
    {
      path: "/vendors/:vendorId/products/navigate",
      component: LoadableComponent({
        loader: () => import("../main/stock-location-tabs/index"),
      }),
    },
    {
      path: "/vendors/:vendorId/products/stock-count/:stockCountId",
      component: LoadableComponent({
        loader: () => import("../main/view-stock-count"),
      }),
    },
    {
      path: "/vendors/:vendorId/products/stock-adjustment/:stockCountId",
      component: LoadableComponent({
        loader: () => import("../main/view-stock-adjustment"),
      }),
    },
    {
      path: "/vendors/:vendorId/products/stock-transfer/:stockTransferId",
      component: LoadableComponent({
        loader: () => import("../main/view-stock-transfer"),
      }),
    },
    {
      path: "/vendors/:vendorId/products/suppliers",
      component: LoadableComponent({
        loader: () => import("../main/suppliers-list"),
      }),
    },
    {
      path: "/vendors/:vendorId/products/suppliers-tab",
      component: LoadableComponent({
        loader: () => import("../main/suppliers-tab"),
      }),
    },
    {
      path: "/vendors/:vendorId/products/suppliers-tab/add",
      component: LoadableComponent({
        loader: () => import("../main/add-edit-supplier"),
      }),
    },
    {
      path: "/vendors/:vendorId/products/suppliers-tab/:supplierId/edit",
      component: LoadableComponent({
        loader: () => import("../main/add-edit-supplier"),
      }),
    },
    {
      path: "/vendors/:vendorId/products/suppliers-tab/add-edit-purchase-order/:isBackdate",
      component: LoadableComponent({
        loader: () => import("../main/add-edit-purchase-order"),
      }),
    },
    {
      path: "/vendors/:vendorId/products/suppliers-tab/add-edit-purchase-order/:isBackdate/:supplierId/:stockLocationId",
      component: LoadableComponent({
        loader: () => import("../main/add-edit-purchase-order/EditPurchaseOrder"),
      }),
    },
    {
      path: "/vendors/:vendorId/products/suppliers-tab/add-edit-purchase-order/:isBackdate/:supplierId",
      component: LoadableComponent({
        loader: () => import("../main/add-edit-purchase-order/EditPurchaseOrder"),
      }),
    },
    {
      path: "/vendors/:vendorId/products/suppliers-tab/purchaseOrder/:purchaseOrderId/:isBackdate",
      component: LoadableComponent({
        loader: () => import("../main/add-edit-purchase-order/EditPurchaseOrder"),
      }),
    },
    {
      path: "/vendors/:vendorId/products/suppliers-tab/purchaseOrder/:purchaseOrderId/view-products/:isBackdate",
      component: LoadableComponent({
        loader: () => import("../main/add-edit-purchase-order/EditPurchaseOrder"),
      }),
    },
    {
      path: "/vendors/:vendorId/products/suppliers-tab/add-supplier",
      component: LoadableComponent({
        loader: () => import("../main/add-edit-supplier"),
      }),
    },
    {
      path: "/vendors/:vendorId/products/suppliers-tab/add-purchase-order",
      component: LoadableComponent({
        loader: () => import("../main/add-edit-purchase-order"),
      }),
    },
    // {
    //   path: "/vendors/:vendorId/products/suppliers/:supplierId/view-products",
    //   component: LoadableComponent({
    //     loader: () => import("../main/supplier-view-products-list"),
    //   }),
    // },
    {
      path: "/vendors/:vendorId/products/suppliers-tab/:supplierId/view-products",
      component: LoadableComponent({
        loader: () => import("../main/supplier-view-products-list"),
      }),
    },
    {
      path: "/vendors/:vendorId/products/items",
      component: LoadableComponent({
        loader: () => import("../main/items-list"),
      }),
    },
    {
      path: "/vendors/:vendorId/products/items/bulk-upload",
      component: LoadableComponent({
        loader: () => import("../main/add-edit-item/bulk-upload"),
      }),
    },
    {
      path: "/vendors/:vendorId/products/stock-locations/:stockLocationId/items",
      component: LoadableComponent({
        loader: () => import("../main/items-list"),
      }),
    },
    {
      path: "/vendors/:vendorId/products/stock-locations/:stockLocationId/items/bulk-upload",
      component: LoadableComponent({
        loader: () => import("../main/add-edit-item/bulk-upload"),
      }),
    },
    {
      path: "/vendors/:vendorId/products/stock-locations",
      component: LoadableComponent({
        loader: () => import("../main/stock-locations-list"),
      }),
    },
    {
      path: "/vendors/:vendorId/products/stock-locations/add",
      component: LoadableComponent({
        loader: () => import("../main/add-edit-stock-location"),
      }),
    },
    {
      path: "/vendors/:vendorId/products/stock-locations/:stockLocationId/edit",
      component: LoadableComponent({
        loader: () => import("../main/add-edit-stock-location"),
      }),
    },
    {
      path: "/vendors/:vendorId/products/stock-locations/:stockLocationId/assign-manager",
      component: LoadableComponent({
        loader: () => import("../main/stock-locations-assign-manager"),
      }),
    },
    {
      path: "/vendors/:vendorId/products/set-alerts",
      component: LoadableComponent({
        loader: () => import("../main/set-stock-alerts"),
      }),
    },
    {
      path: "/vendors/:vendorId/products/stock-locations/:stockLocationId/set-alerts",
      component: LoadableComponent({
        loader: () => import("../main/set-stock-alerts"),
      }),
    },
    {
      path: "/vendors/:vendorId/products/stock-locations/:stockLocationId/transfer",
      component: LoadableComponent({
        loader: () => import("../main/stock-locations-transfer"),
      }),
    },
    {
      path: "/vendors/:vendorId/products/stock-locations/:stockLocationId/navigate",
      component: LoadableComponent({
        loader: () => import("../main/stock-location-tabs/index"),
      }),
    },
    {
      path: "/vendors/:vendorId/products/stock-locations/:stockLocationId/products",
      component: LoadableComponent({
        loader: () => import("../main/stock-location-products"),
      }),
    },
    {
      path: "/vendors/:vendorId/products/modifier-group",
      component: LoadableComponent({
        loader: () => import("../main/modifier-group-list"),
      }),
    },
    {
      path: "/vendors/:vendorId/products/stock-locations/:stockLocationId/modifier-group",
      component: LoadableComponent({
        loader: () => import("../main/modifier-group-list"),
      }),
    },
    {
      path: "/vendors/:vendorId/products/modifier-group/add",
      component: LoadableComponent({
        loader: () => import("../main/add-edit-modifier-group"),
      }),
    },
    {
      path: "/vendors/:vendorId/products/stock-locations/:stockLocationId/modifier-group/add",
      component: LoadableComponent({
        loader: () => import("../main/add-edit-modifier-group"),
      }),
    },
    {
      path: "/vendors/:vendorId/products/modifier-group/:groupId",
      component: LoadableComponent({
        loader: () => import("../main/add-edit-modifier-group"),
      }),
    },
    {
      path: "/vendors/:vendorId/products/stock-locations/:stockLocationId/modifier-group/:groupId",
      component: LoadableComponent({
        loader: () => import("../main/add-edit-modifier-group"),
      }),
    },
    {
      path: "/vendors/:vendorId/products/items/add",
      component: LoadableComponent({
        loader: () => import("../main/add-edit-item"),
      }),
    },
    {
      path: "/vendors/:vendorId/products/stock-locations/:stockLocationId/items/add",
      component: LoadableComponent({
        loader: () => import("../main/add-edit-item"),
      }),
    },
    {
      path: "/vendors/:vendorId/products/items/add-category",
      component: LoadableComponent({
        loader: () => import("../main/item-category"),
      }),
    },
    {
      path: "/vendors/:vendorId/products/stock-locations/:stockLocationId/items/add-category",
      component: LoadableComponent({
        loader: () => import("../main/item-category"),
      }),
    },
    {
      path: "/vendors/:vendorId/products/items/edit/:itemId",
      component: LoadableComponent({
        loader: () => import("../main/add-edit-item"),
      }),
    },
    {
      path: "/vendors/:vendorId/products/stock-locations/:stockLocationId/items/edit/:itemId",
      component: LoadableComponent({
        loader: () => import("../main/add-edit-item"),
      }),
    },
    // {
    //   path: "/vendors/:vendorId/products/suppliers/add",
    //   component: LoadableComponent({
    //     loader: () => import("../main/add-edit-supplier"),
    //   }),
    // },
    // {
    //   path: "/vendors/:vendorId/products/suppliers/:supplierId/edit",
    //   component: LoadableComponent({
    //     loader: () => import("../main/add-edit-supplier"),
    //   }),
    // },
    {
      path: "/vendors/:vendorId/outlets/add",
      component: LoadableComponent({
        loader: () => import("../main/add-edit-outlets"),
      }),
    },
    {
      path: "/vendors/:vendorId/outlets/:outletId",
      component: LoadableComponent({
        loader: () => import("../main/outlet-edit-navigation"),
      }),
    },
    {
      path: "/vendors/:vendorId/outlets/:outletId/edit",
      component: LoadableComponent({
        loader: () => import("../main/add-edit-outlets"),
      }),
    },
    {
      path: "/vendors/:vendorId/outlets/:outletId/services",
      component: LoadableComponent({
        loader: () => import("../main/outlet-services-navigation"),
      }),
    },
    {
      path: "/vendors/:vendorId/outlets/:outletId/on-site",
      component: LoadableComponent({
        loader: () => import("../main/outlet-onsite-service"),
      }),
    },
    {
      path: "/vendors/:vendorId/outlets/:outletId/on-site/counter-order",
      component: LoadableComponent({
        loader: () => import("../main/outlet-onsite-service/counter-order"),
      }),
    },
    {
      path: "/vendors/:vendorId/outlets/:outletId/on-site/off-counter-order",
      component: LoadableComponent({
        loader: () => import("../main/outlet-onsite-service/off-counter-order"),
      }),
    },
    {
      path: "/vendors/:vendorId/outlets/:outletId/on-site/collection",
      component: LoadableComponent({
        loader: () => import("../main/outlet-onsite-service/collection"),
      }),
    },
    {
      path: "/vendors/:vendorId/outlets/:outletId/on-site/express",
      component: LoadableComponent({
        loader: () => import("../main/outlet-onsite-service/express"),
      }),
    },
    {
      path: "/vendors/:vendorId/outlets/:outletId/on-site/delivery",
      component: LoadableComponent({
        loader: () => import("../main/outlet-onsite-service/delivery"),
      }),
    },
    {
      path: "/vendors/:vendorId/outlets/:outletId/on-site/kiosk",
      component: LoadableComponent({
        loader: () => import("../main/outlet-onsite-service/kiosk"),
      }),
    },
    {
      path: "/vendors/:vendorId/outlets/:outletId/on-site/kiosk/add",
      component: LoadableComponent({
        loader: () => import("../main/outlet-onsite-service/kiosk/add-edit-kiosk"),
      }),
    },
    {
      path: "/vendors/:vendorId/outlets/:outletId/on-site/kiosk/edit/:kioskUserId",
      component: LoadableComponent({
        loader: () => import("../main/outlet-onsite-service/kiosk/add-edit-kiosk"),
      }),
    },
    {
      path: "/vendors/:vendorId/outlets/:outletId/off-site",
      component: LoadableComponent({
        loader: () => import("../main/outlet-offsite-service"),
      }),
    },
    {
      path: "/vendors/:vendorId/outlets/:outletId/off-site/collection",
      component: LoadableComponent({
        loader: () => import("../main/outlet-offsite-service/collection"),
      }),
    },
    {
      path: "/vendors/:vendorId/outlets/:outletId/off-site/delivery",
      component: LoadableComponent({
        loader: () => import("../main/outlet-offsite-service/delivery"),
      }),
    },
    {
      path: "/vendors/:vendorId",
      component: LoadableComponent({
        loader: () => import("../main/vendor-edit-navigation"),
      }),
    },
    {
      path: "/vendors/:vendorId/edit-profile",
      component: LoadableComponent({
        loader: () => import("../main/vendor-profile"),
      }),
    },
    {
      path: "/vendors/add",
      component: LoadableComponent({
        loader: () => import("../main/vendor-profile"),
      }),
    },
    {
      path: "/vendors/:vendorId/users",
      component: LoadableComponent({
        loader: () => import("../main/user-edit-navigation"),
      }),
    },
    {
      path: "/vendors/:vendorId/users/staff/add",
      component: LoadableComponent({
        loader: () => import("../main/add-edit-staff"),
      }),
    },
    {
      path: "/vendors/:vendorId/users/staff/edit/:staffId",
      component: LoadableComponent({
        loader: () => import("../main/add-edit-staff"),
      }),
    },
    {
      path: "/vendors/:vendorId/users/staff/bulk-upload",
      component: LoadableComponent({
        loader: () => import("../main/staff-bulk-upload"),
      }),
    },
    {
      path: "/vendors/:vendorId/users/staff/:staffId/assign-store",
      component: LoadableComponent({
        loader: () => import("../main/assign-store-staff"),
      }),
    },
    {
      path: "/vendors/:vendorId/outlets/:outletId/floor-management",
      component: LoadableComponent({
        loader: () => import("../main/floor-management"),
      }),
    },
    {
      path: "/vendors/:vendorId/users/managers",
      component: LoadableComponent({
        loader: () => import("../main/managers-list"),
      }),
    },
    {
      path: "/vendors/:vendorId/users/staff",
      component: LoadableComponent({
        loader: () => import("../main/staff-list"),
      }),
    },
    {
      path: "/vendors/:vendorId/users/external-users",
      component: LoadableComponent({
        loader: () => import("../main/external-users-list"),
      }),
    },
    {
      path: "/vendors/:vendorId/users/external-users/add",
      component: LoadableComponent({
        loader: () => import("../main/add-edit-external-user"),
      }),
    },
    {
      path: "/vendors/:vendorId/users/external-users/edit/:externalUserId",
      component: LoadableComponent({
        loader: () => import("../main/add-edit-external-user"),
      }),
    },
    {
      path: "/vendors/:vendorId/users/managers/add",
      component: LoadableComponent({
        loader: () => import("../main/add-edit-manager"),
      }),
    },
    {
      path: "/vendors/:vendorId/users/managers/edit/:managerId",
      component: LoadableComponent({
        loader: () => import("../main/add-edit-manager"),
      }),
    },
    {
      path: "/vendors/:vendorId/users/managers/:managerId/assign-store",
      component: LoadableComponent({
        loader: () => import("../main/assign-store-manager"),
      }),
    },
    {
      path: "/vendors/:vendorId/merchant-service-account",
      component: LoadableComponent({
        loader: () => import("../main/merchant-account-list"),
      }),
    },
    {
      path: "/vendors/:vendorId/merchant-service-account/add",
      component: LoadableComponent({
        loader: () => import("../main/add-merchant-account"),
      }),
    },
    {
      path: "/vendors/:vendorId/merchant-service-account/:accountId",
      component: LoadableComponent({
        loader: () => import("../main/add-merchant-account"),
      }),
    },
    {
      path: "/vendors/:vendorId/merchant-service-account/:accountId/fees",
      component: LoadableComponent({
        loader: () => import("../main/merchant-account-fee"),
      }),
    },
    {
      path: "/vendors/:vendorId/merchant-service-account/:accountId/fees/:type",
      component: LoadableComponent({
        loader: () => import("../main/add-edit-fees"),
      }),
    },
    {
      path: "/vendors/:vendorId/merchant-service-account/:accountId/balance-accounts",
      component: LoadableComponent({
        loader: () => import("../main/balance-accounts-list"),
      }),
    },
    {
      path: "/vendors/:vendorId/outlets/:outletId/financial-setup",
      component: LoadableComponent({
        loader: () => import("../main/outlet-financial-setup"),
      }),
    },
    {
      path: "/vendors/:vendorId/sites",
      component: LoadableComponent({
        loader: () => import("../main/sites-navigation"),
      }),
    },
    {
      path: "/vendors/:vendorId/sites/own-sites",
      component: LoadableComponent({
        loader: () => import("../main/sites-list"),
      }),
    },
    {
      path: "/vendors/:vendorId/sites/invited-sites",
      component: LoadableComponent({
        loader: () => import("../main/invited-sites-list"),
      }),
    },
    {
      path: "/vendors/:vendorId/sites/invited-sites/:invitedSiteId/:invitedBusinessId",
      component: LoadableComponent({
        loader: () => import("../main/add-outlets-to-invited-site"),
      }),
    },
    {
      path: "/vendors/:vendorId/sites/:siteId/add-zones",
      component: LoadableComponent({
        loader: () => import("../main/add-zones"),
      }),
    },
    {
      path: "/vendors/:vendorId/sites/:siteId/assign-store",
      component: LoadableComponent({
        loader: () => import("../main/add-outlets"),
      }),
    },
    {
      path: "/vendors/:vendorId/sites/:siteId/split-arrange/:splitArrangementId/assign-store",
      component: LoadableComponent({
        loader: () => import("../main/add-outlets-split-arrangement"),
      }),
    },
    {
      path: "/vendors/:vendorId/sites/:siteId/invite-business",
      component: LoadableComponent({
        loader: () => import("../main/invite-external-business"),
      }),
    },
    {
      path: "/vendors/:vendorId/sites/:siteId/invite-business/:invitedBusinessId",
      component: LoadableComponent({
        loader: () => import("../main/invite-external-business"),
      }),
    },
    {
      path: "/vendors/:vendorId/sites/:siteId/add-split",
      component: LoadableComponent({
        loader: () => import("../main/add-split-arrangement"),
      }),
    },
    {
      path: "/vendors/:vendorId/sites/:siteId/edit-split/:splitId",
      component: LoadableComponent({
        loader: () => import("../main/add-split-arrangement"),
      }),
    },
    {
      path: "/vendors/:vendorId/sites/add",
      component: LoadableComponent({
        loader: () => import("../main/add-edit-sites"),
      }),
    },
    {
      path: "/vendors/:vendorId/sites/:siteId",
      component: LoadableComponent({
        loader: () => import("../main/site-edit-navigation"),
      }),
    },
    {
      path: "/vendors/:vendorId/sites/:siteId/branding",
      component: LoadableComponent({
        loader: () => import("../main/site-branding"),
      })
    },
    {
      path: "/vendors/:vendorId/sites/:siteId/details",
      component: LoadableComponent({
        loader: () => import("../main/add-edit-sites"),
      }),
    },
    {
      path: "/vendors/:vendorId/sites/:siteId/service-types",
      component: LoadableComponent({
        loader: () => import("../main/site-service-types"),
      }),
    },
    {
      path: "/vendors/:vendorId/sites/:siteId/service-types/collection",
      component: LoadableComponent({
        loader: () => import("../main/site-service-types/OnSiteCollection"),
      }),
    },
    {
      path: "/vendors/:vendorId/sites/:siteId/service-types/delivery",
      component: LoadableComponent({
        loader: () => import("../main/site-service-types/OnSiteDelivery"),
      }),
    },
    {
      path: "/vendors/:vendorId/sites/:siteId/service-types/express",
      component: LoadableComponent({
        loader: () => import("../main/site-service-types/Express"),
      }),
    },
    {
      path: "/vendors/:vendorId/sites/:siteId/assign-users",
      component: LoadableComponent({
        loader: () => import("../main/set-site-users"),
      }),
    },
    {
      path: "/vendors/:vendorId/sites/:siteId/printers",
      component: LoadableComponent({
        loader: () => import("../main/printer-list"),
      }),
    },
    {
      path: "/vendors/:vendorId/products/ingredients",
      component: LoadableComponent({
        loader: () => import("../main/ingredients-list"),
      }),
    },
    {
      path: "/vendors/:vendorId/products/stock-locations/:stockLocationId/ingredients",
      component: LoadableComponent({
        loader: () => import("../main/ingredients-list"),
      }),
    },
    {
      path: "/vendors/:vendorId/products/ingredients/:ingredientId/edit",
      component: LoadableComponent({
        loader: () => import("../main/add-edit-ingredient"),
      }),
    },
    {
      path: "/vendors/:vendorId/products/ingredients/add",
      component: LoadableComponent({
        loader: () => import("../main/add-edit-ingredient"),
      }),
    },
    {
      path: "/vendors/:vendorId/products/stock-locations/:stockLocationId/ingredients/:ingredientId/edit",
      component: LoadableComponent({
        loader: () => import("../main/add-edit-ingredient"),
      }),
    },
    {
      path: "/vendors/:vendorId/products/stock-locations/:stockLocationId/ingredients/add",
      component: LoadableComponent({
        loader: () => import("../main/add-edit-ingredient"),
      }),
    },
    {
      path: "/vendors/:vendorId/products/ingredients/add-category",
      component: LoadableComponent({
        loader: () => import("../main/ingredient-category"),
      }),
    },
    {
      path: "/vendors/:vendorId/products/ingredients/bulk-upload-ingredient",
      component: LoadableComponent({
        loader: () => import("../main/bulk-upload-ingredient"),
      }),
    },
    {
      path: "/vendors/:vendorId/products/stock-locations/:stockLocationId/ingredients/add-category",
      component: LoadableComponent({
        loader: () => import("../main/ingredient-category"),
      }),
    },
    {
      path: "/vendors/:vendorId/products/stock-locations/:stockLocationId/ingredients/bulk-upload-ingredient",
      component: LoadableComponent({
        loader: () => import("../main/bulk-upload-ingredient"),
      }),
    },
    {
      path: "/vendors/:vendorId/products/modifiers",
      component: LoadableComponent({
        loader: () => import("../main/modifiers-list"),
      }),
    },
    {
      path: "/vendors/:vendorId/products/stock-locations/:stockLocationId/modifiers",
      component: LoadableComponent({
        loader: () => import("../main/modifiers-list"),
      }),
    },
    {
      path: "/vendors/:vendorId/products/modifiers/:modifierId",
      component: LoadableComponent({
        loader: () => import("../main/add-edit-modifier"),
      }),
    },
    {
      path: "/vendors/:vendorId/products/stock-locations/:stockLocationId/modifiers/:modifierId",
      component: LoadableComponent({
        loader: () => import("../main/add-edit-modifier"),
      }),
    },
    {
      path: "/vendors/:vendorId/products/modifiers/add",
      component: LoadableComponent({
        loader: () => import("../main/add-edit-modifier"),
      }),
    },
    {
      path: "/vendors/:vendorId/products/stock-locations/:stockLocationId/modifiers/add",
      component: LoadableComponent({
        loader: () => import("../main/add-edit-modifier"),
      }),
    },
    {
      path: "/vendors/:vendorId/products/menus",
      component: LoadableComponent({
        loader: () => import("../main/menu-list"),
      }),
    },
    {
      path: "/vendors/:vendorId/products/menus/bulk-upload-menu",
      component: LoadableComponent({
        loader: () => import("../main/bulk-upload-menu"),
      }),
    },
    {
      path: "/vendors/:vendorId/products/menus/:menuId/edit",
      component: LoadableComponent({
        loader: () => import("../main/add-edit-menu"),
      }),
    },
    {
      path: "/vendors/:vendorId/products/menus/add",
      component: LoadableComponent({
        loader: () => import("../main/add-edit-menu"),
      }),
    },
    {
      path: "/vendors/:vendorId/products/menus/:menuId/edit/prepaid-menu-email-listing",
      component: LoadableComponent({
        loader: () => import("../main/add-edit-prepaid-menu-email-listing"),
      }),
    },
    {
      path: "/vendors/:vendorId/products/menus/:menuId/add-items",
      component: LoadableComponent({
        loader: () => import("../main/menu-detail-list"),
      }),
    },
    {
      path: "/notifications",
      component: LoadableComponent({
        loader: () => import("../main/notification-list"),
      }),
    },
    {
      path: "/vendors/:vendorId/outlets/:outletId/display",
      component: LoadableComponent({
        loader: () => import("../main/display"),
      }),
    },
    {
      path: "/vendors/:vendorId/outlets/:outletId/menus",
      component: LoadableComponent({
        loader: () => import("../main/outlets-menu-list"),
      }),
    },
    {
      path: "/vendors/:vendorId/outlets/:outletId/menus/:serviceType",
      component: LoadableComponent({
        loader: () => import("../main/add-edit-outlets-menu"),
      }),
    },
    {
      path: "/vendors/:vendorId/outlets/:outletId/menus/:serviceType/menu-times",
      component: LoadableComponent({
        loader: () => import("../main/add-edit-outlets-menu/menu-times"),
      }),
    },
    {
      path: "/vendors/:vendorId/outlets/:outletId/menus/:serviceType/manage-menu/:menuId/:assignedMenuId",
      component: LoadableComponent({
        loader: () =>
          import("../main/add-edit-outlets-menu/manage-outlet-menu"),
      }),
    },
    {
      path: "/vendors/:vendorId/outlets/:outletId/menus/:serviceType/pre-order/:menuId",
      component: LoadableComponent({
        loader: () =>
          import("../main/add-edit-outlets-menu/add-edit-pre-order-setup"),
      }),
    },
    {
      path: "/vendors/:vendorId/reports",
      component: LoadableComponent({
        loader: () => import("../main/reporting-navigation"),
      }),
    },
    {
      path: "/vendors/:vendorId/reports/menu-report-navigation",
      component: LoadableComponent({
        loader: () => import("../main/menu-reporting-navigation"),
      }),
    },
    {
      path: "/vendors/:vendorId/reports/menu",
      component: LoadableComponent({
        loader: () => import("../main/menu-reporting"),
      }),
    },
    {
      path: "/vendors/:vendorId/reports/external-stores-menu",
      component: LoadableComponent({
        loader: () => import("../main/menu-reporting"),
      }),
    },
    {
      path: "/vendors/:vendorId/reports/product",
      component: LoadableComponent({
        loader: () => import("../main/product-report"),
      }),
    },
    {
      path: "vendors/:vendorId/reports/profit-and-loss",
      component: LoadableComponent({
        loader: () => import("../main/profit-and-loss-navigation"),
      }),
    },
    {
      path: "/vendors/:vendorId/reports/profit-and-loss/site-reports",
      component: LoadableComponent({
        loader: () => import("../main/profit-and-loss-reports"),
      }),
    },
    {
      path: "/vendors/:vendorId/reports/profit-and-loss/site-report",
      component: LoadableComponent({
        loader: () => import("../main/profit-and-loss-report"),
      }),
    },
    {
      path: "/vendors/:vendorId/reports/profit-and-loss/invited-site-report",
      component: LoadableComponent({
        loader: () => import("../main/profit-and-loss-invited-site"),
      }),
    },
    {
      path: "/vendors/:vendorId/reports/profit-and-loss/outlet-reports",
      component: LoadableComponent({
        loader: () => import("../main/profit-and-loss-store-report"),
      }),
    },
    {
      path: "/vendors/:vendorId/reports/stock-report",
      component: LoadableComponent({
        loader: () => import("../main/stock-report"),
      }),
    },
    {
      path: "/vendors/:vendorId/reports/new-stock-report",
      component: LoadableComponent({
        loader: () => import("../main/new-stock-report"),
      }),
    },
    {
      path: "/vendors/:vendorId/reports/stock-movement-report",
      component: LoadableComponent({
        loader: () => import("../main/stock-movement-report"),
      }),
    },
    {
      path: "vendors/:vendorId/reports/employee-report",
      component: LoadableComponent({
        loader: () => import("../main/employee-report")
      })
    },
    {
      path: "vendors/:vendorId/reports/employee-report/employee/:employeeId/assignVendor/:assignVendorId",
      component: LoadableComponent({
        loader: () => import("../main/add-edit-clock-record"),
      })
    },
    {
      path: "vendors/:vendorId/reports/employee-report/edit/:clockRecordId",
      component: LoadableComponent({
        loader: () => import("../main/add-edit-clock-record"),
      })
    },
    {
      path: "/vendors/:vendorId/reports/demo/menu",
      component: LoadableComponent({
        loader: () => import("../main/menu-reporting/menu-report"),
      }),
    },

    {
      path: "/vendors/:vendorId/reports/sales",
      component: LoadableComponent({
        loader: () => import("../main/sales-report"),
      }),
    },
    {
      path: "/vendors/:vendorId/outlets/:outletId/printerReceipt",
      component: LoadableComponent({
        loader: () => import("../main/printer-receipts"),
      }),
    },
    {
      path: "/vendors/:vendorId/outlets/:outletId/emailReceipts",
      component: LoadableComponent({
        loader: () => import("../main/email-receipts"),
      }),
    },
    {
      path: "/vendors/:vendorId/outlets/:outletId/emailReceipts/add",
      component: LoadableComponent({
        loader: () => import("../main/add-edit-email-receipt"),
      }),
    },
    {
      path: "/vendors/:vendorId/outlets/:outletId/emailReceipts/edit/:emailTempId",
      component: LoadableComponent({
        loader: () => import("../main/add-edit-email-receipt"),
      }),
    },
    {
      path: "/vendors/:vendorId/promotions/add",
      component: LoadableComponent({
        loader: () => import("../main/add-edit-promotion"),
      }),
    },
    {
      path: "/vendors/:vendorId/promotions/:promoId",
      component: LoadableComponent({
        loader: () => import("../main/add-edit-promotion"),
      }),
    },
    {
      path: "/vendors/:vendorId/promotions",
      component: LoadableComponent({
        loader: () => import("../main/promotions-list"),
      }),
    },
    {
      path: "/vendors/:vendorId/promotions/:promoId/membership-listing",
      component: LoadableComponent({
        loader: () => import("../main/add-edit-promotion-membership-listing"),
      }),
    },
    {
      path: "/vendors/:vendorId/loyalty",
      component: LoadableComponent({
        loader: () => import("../main/loyalty-navigation"),
      }),
    },
    {
      path: "/vendors/:vendorId/add-loyalty/:loyaltyType",
      component: LoadableComponent({
        loader: () => import("../main/add-edit-loyalty"),
      }),
    },
    {
      path: "/vendors/:vendorId/site-loyalty",
      component: LoadableComponent({
        loader: () => import("../main/site-loyalty-list"),
      }),
    },
    {
      path: "/vendors/:vendorId/site-loyalty/:loyaltyId/add-site",
      component: LoadableComponent({
        loader: () => import("../main/add-sites-loyalty"),
      }),
    },
    {
      path: "/vendors/:vendorId/outlet-loyalty",
      component: LoadableComponent({
        loader: () => import("../main/outlet-loyalty-list"),
      }),
    },
    {
      path: "/vendors/:vendorId/sites/:siteId/assigned-promotions",
      component: LoadableComponent({
        loader: () => import("../main/assign-site-promotions"),
      }),
    },
    {
      path: "/vendors/:vendorId/sites/:siteId/assigned-promotions/:promotionId/assign-items",
      component: LoadableComponent({
        loader: () => import("../main/assign-items-to-promotion"),
      }),
    },
    {
      path: "/vendors/:vendorId/sites/:siteId/assigned-promotions/:promotionId/view-all-items",
      component: LoadableComponent({
        loader: () => import("../main/view-all-assigned-item-promotion"),
      }),
    },
    {
      path: "/vendors/:vendorId/sites/:siteId/memberships",
      component: LoadableComponent({
        loader: () => import("../main/add-edit-site-membership-listing"),
      }),
    },
    {
      path: "/vendors/:vendorId/outlet-loyalty/:loyaltyId/add-outlet",
      component: LoadableComponent({
        loader: () => import("../main/add-outlets-loyalty"),
      }),
    },
    {
      path: "/vendors/:vendorId/feedback/tickets",
      component: LoadableComponent({
        loader: () => import("../main/tickets-listing"),
      }),
    },
    {
      path: "/vendors/:vendorId/feedback",
      component: LoadableComponent({
        loader: () => import("../main/feedback-navigation"),
      }),
    },
    {
      path: "/vendors/:vendorId/feedback/reviews",
      component: LoadableComponent({
        loader: () => import("../main/feedback-reviews"),
      }),
    },
    {
      path: "/vendors/:vendorId/reports/customer",
      component: LoadableComponent({
        loader: () => import("../main/customer-report-navigation"),
      }),
    },
    {
      path: "/vendors/:vendorId/reports/customer/site-report",
      component: LoadableComponent({
        loader: () => import("../main/customer-report"),
      }),
    },
    {
      path: "/vendors/:vendorId/reports/customer/outlet-report",
      component: LoadableComponent({
        loader: () => import("../main/customer-report"),
      }),
    },
    {
      path: "vendors/:vendorId/reports/order-report",
      component: LoadableComponent({
        loader: () => import("../main/order-report-navigation")
      })
    },
    {
      path: "vendors/:vendorId/reports/site-order-report",
      component: LoadableComponent({
        loader: () => import("../main/order-report/sites-order-report")
      })
    },
    {
      path: "vendors/:vendorId/reports/store-order-report",
      component: LoadableComponent({
        loader: () => import("../main/order-report/stores-order-report")
      })
    },
    {
      path: "vendors/:vendorId/reports/invited-site-order-report",
      component: LoadableComponent({
        loader: () => import("../main/order-report/invited-sites-order-report")
      })
    },
    {
      path: "vendors/:vendorId/reports/transaction-report",
      component: LoadableComponent({
        loader: () => import("../main/transaction-report")
      })
    },
    {
      path: "vendors/:vendorId/reports/transaction-report/in-person",
      component: LoadableComponent({
        loader: () => import("../main/transaction-report/in-person")
      })
    },
    {
      path: "vendors/:vendorId/reports/transaction-report/online",
      component: LoadableComponent({
        loader: () => import("../main/transaction-report/online")
      })
    },
    {
      path: "vendors/:vendorId/reports/print-job",
      component: LoadableComponent({
        loader: () => import("../main/print-job")
      })
    },
    {
      path: "vendors/:vendorId/reports/print-log",
      component: LoadableComponent({
        loader: () => import("../main/print-logs")
      })
    },
    {
      path: "vendors/:vendorId/reports/cash-history",
      component: LoadableComponent({
        loader: () => import("../main/cash-history")
      })
    },
    {
      path: "vendors/:vendorId/reports/cash-position",
      component: LoadableComponent({
        loader: () => import("../main/cash-position")
      })
    },
    {
      path: "/admin-reports",
      component: LoadableComponent({
        loader: () => import("../main/admin-reporting-navigation")
      })
    },
    {
      path: "/admin-reports/profit-and-loss",
      component: LoadableComponent({
        loader: () => import("../main/profit-and-loss-navigation")
      })
    },
    {
      path: "/admin-reports/profit-and-loss/site-reports",
      component: LoadableComponent({
        loader: () => import("../main/profit-and-loss-reports")
      })
    },
    {
      path: "/admin-reports/profit-and-loss/outlet-reports",
      component: LoadableComponent({
        loader: () => import("../main/profit-and-loss-reports"),
      }),
    },
    {
      path: "/admin-reports/transaction",
      component: LoadableComponent({
        loader: () => import("../main/admin-transaction-report")
      })
    },
    {
      path: "/admin-reports/transaction/in-person",
      component: LoadableComponent({
        loader: () => import("../main/admin-transaction-report/in-person")
      })
    },
    {
      path: "/admin-reports/transaction/online",
      component: LoadableComponent({
        loader: () => import("../main/admin-transaction-report/online"),
      }),
    },
    {
      path: "/admin-reports/transaction/pay-by-link",
      component: LoadableComponent({
        loader: () => import("../main/admin-transaction-report/pay-by-link"),
      }),
    },
    {
      path: "/admin-reports/transaction/merchandise",
      component: LoadableComponent({
        loader: () => import("../main/admin-transaction-report/merchandise"),
      }),
    },
    {
      path: "/admin-reports/custom-csv",
      component: LoadableComponent({
        loader: () => import("../main/manual-csv-list")
      }),
    },
    {
      path: "/admin-reports/custom-csv/add",
      component: LoadableComponent({
        loader: () => import("../main/add-manual-csv")
      }),
    },
    {
      path: "vendors/:vendorId/reports/payment-invoices",
      component: LoadableComponent({
        loader: () => import("../main/payment-invoices")
      })
    },
    {
      path: "vendors/:vendorId/adyen-devices",
      component: LoadableComponent({
        loader: () => import("../main/adyen-device-list")
      })
    },
    {
      path: "vendors/:vendorId/devices",
      component: LoadableComponent({
        loader: () => import("../main/device-list-navigation")
      })
    },
    {
      path: "vendors/:vendorId/other-devices",
      component: LoadableComponent({
        loader: () => import("../main/other-devices-list")
      })
    },
    {
      path: "vendors/:vendorId/reports/payout-report",
      component: LoadableComponent({
        loader: () => import("../main/payout-report")
      })
    },
    {
      path: "vendors/:vendorId/reports/custom-csv",
      component: LoadableComponent({
        loader: () => import("../main/manual-csv-list")
      })
    },
    {
      path: "vendors/:vendorId/reports/custom-csv/add",
      component: LoadableComponent({
        loader: () => import("../main/add-manual-csv")
      })
    },
    {
      path: "vendors/:vendorId/reports/product-detail",
      component: LoadableComponent({
        loader: () => import("../main/product-detail-report")
      })
    },
    {
      path: "/vendors/:vendorId/reports/menu-detail-report",
      component: LoadableComponent({
        loader: () => import("../main/menu-detail-report-navigation"),
      }),
    },
    {
      path: "vendors/:vendorId/reports/menu-detail-report/own-menu-detail",
      component: LoadableComponent({
        loader: () => import("../main/menu-detail-report/own-store")
      })
    },
    {
      path: "vendors/:vendorId/reports/menu-detail-report/invited-menu-detail",
      component: LoadableComponent({
        loader: () => import("../main/menu-detail-report/external-store")
      })
    },
    {
      path: "/vendors/:vendorId/sites/:siteId/assign-staff",
      component: LoadableComponent({
        loader: () => import("../main/assign-site-staff"),
      }),
    },
    {
      path: "/vendors/:vendorId/merchandise",
      component: LoadableComponent({
        loader: () => import("../main/merchandise-edit-navigation"),
      }),
    },
    {
      path: "/vendors/:vendorId/merchandise/stock-locations",
      component: LoadableComponent({
        loader: () => import("../main/merchandise-stock-location-list"),
      }),
    },
    {
      path: "/vendors/:vendorId/merchandise/stock-locations/add",
      component: LoadableComponent({
        loader: () => import("../main/add-edit-merchandise-stock-location"),
      }),
    },
    {
      path: "/vendors/:vendorId/merchandise/stock-locations/:stockLocationId/edit",
      component: LoadableComponent({
        loader: () => import("../main/add-edit-merchandise-stock-location"),
      }),
    },
    {
      path: "/vendors/:vendorId/merchandise/stock-locations/:stockLocationId/assign-manager",
      component: LoadableComponent({
        loader: () => import("../main/add-manager-merchandise-location"),
      }),
    },
    {
      path: "/vendors/:vendorId/merchandise/stock-locations/:stockLocationId/assign-store",
      component: LoadableComponent({
        loader: () => import("../main/assign-store-merchandise-location"),
      }),
    },
    {
      path: "/vendors/:vendorId/merchandise/stock-locations/:stockLocationId/manage-stock",
      component: LoadableComponent({
        loader: () => import("../main/manage-merchandise-stock"),
      }),
    },
    {
      path: "/vendors/:vendorId/merchandise/stock-locations/:stockLocationId/manage-stock/add-remove-category",
      component: LoadableComponent({
        loader: () => import("../main/add-merchandise-category"),
      }),
    },
    {
      path: "/vendors/:vendorId/merchandise/stock-locations/:stockLocationId/manage-stock/add-item",
      component: LoadableComponent({
        loader: () => import("../main/add-edit-merchandise-item"),
      }),
    },
    {
      path: "/vendors/:vendorId/merchandise/stock-locations/:stockLocationId/manage-stock/:itemId/edit",
      component: LoadableComponent({
        loader: () => import("../main/add-edit-merchandise-item"),
      }),
    },
    {
      path: "/vendors/:vendorId/merchandise/stock-locations/:stockLocationId/set-stock-alert",
      component: LoadableComponent({
        loader: () => import("../main/set-merchandise-stock-alert"),
      }),
    },
    {
      path: "/vendors/:vendorId/merchandise/stock-transfer/:stockTransferId",
      component: LoadableComponent({
        loader: () => import("../main/view-merchandise-stock-transfer"),
      }),
    },
    {
      path: "/vendors/:vendorId/merchandise/stock-adjustment/:stockAdjustId",
      component: LoadableComponent({
        loader: () => import("../main/view-merchandise-stock-adjustmnment"),
      }),
    },
    {
      path: "/vendors/:vendorId/merchandise/stock-count/:stockCountId",
      component: LoadableComponent({
        loader: () => import("../main/view-merchandise-stock-count"),
      }),
    },
    {
      path: "/vendors/:vendorId/merchandise/stock-locations/:stockLocationId/manage-stock/upload-items-csv",
      component: LoadableComponent({
        loader: () => import("../main/upload-merchandise-item-csv"),
      })
    },
    // {
    //   path: "/vendors/:vendorId/outlets/:outletId/kds",
    //   component: LoadableComponent({
    //     loader: () => import("../main/kds-list"),
    //   }),
    // },
    {
      path: "/vendors/:vendorId/outlets/:outletId/kds",
      component: LoadableComponent({
        loader: () => import("../main/assign-kds-to-kdsGroup"),
      }),
    },
    {
      path: "/vendors/:vendorId/sites/:siteId/kds",
      component: LoadableComponent({
        loader: () => import("../main/assign-site-kds-to-kdsGroup"),
      }),
    },
    {
      path: "/vendors/:vendorId/sites/:siteId/kds/:kdsGroupId",
      component: LoadableComponent({
        loader: () => import("../main/assign-store-to-kds"),
      }),
    },
    {
      path: "/vendors/:vendorId/sites/:siteId/kds/:kdsGroupId/set-user",
      component: LoadableComponent({
        loader: () => import("../main/set-kds-user"),
      }),
    },
    {
      path: "/vendors/:vendorId/reports/merch-stock-report",
      component: LoadableComponent({
        loader: () => import("../main/merchandise-stock-report"),
      }),
    },
    {
      path: "/vendors/:vendorId/merchandise/stock-locations/manage-stock-group",
      component: LoadableComponent({
        loader: () => import("../main/add-remove-merchandise-stock-group"),
      }),
    },
    {
      path: "/vendors/:vendorId/reports/merch-stock-movement-report",
      component: LoadableComponent({
        loader: () => import("../main/merchandise-stock-movement-report"),
      }),
    },
    {
      path: "/vendors/:vendorId/payment-links",
      component: LoadableComponent({
        loader: () => import("../main/payment-links/index"),
      }),
    },
    {
      path: "/vendors/:vendorId/payment-links/add",
      component: LoadableComponent({
        loader: () => import("../main/payment-links/add-edit-payment-link"),
      }),
    },
    {
      path: "/vendors/:vendorId/payment-links/:paymentLinkId",
      component: LoadableComponent({
        loader: () => import("../main/payment-links/add-edit-payment-link"),
      }),
    },
    {
      path: "/vendors/:vendorId/microservice-products/item/add",
      component: LoadableComponent({
        loader: () => import("../main/microservice-pms/master-stock-list/items/add-edit-item-pms"),
      }),
    },
    {
      path: "/vendors/:vendorId/microservice-products/item/edit/:itemId",
      component: LoadableComponent({
        loader: () => import("../main/microservice-pms/master-stock-list/items/add-edit-item-pms"),
      }),
    },
    {
      path: "/vendors/:vendorId/microservice-products/custom-attributes",
      component: LoadableComponent({
        loader: () => import("../main/custom-attributes-list"),
      }),
    },
    {
      path: "/vendors/:vendorId/microservice-products/custom-attributes/add",
      component: LoadableComponent({
        loader: () => import("../main/add-edit-custom-attribute"),
      }),
    },
    {
      path: "/vendors/:vendorId/microservice-products/custom-attributes/edit/:attributeId",
      component: LoadableComponent({
        loader: () => import("../main/add-edit-custom-attribute"),
      }),
    },
    {
      path: "/vendors/:vendorId/microservice-products/taxes",
      component: LoadableComponent({
        loader: () => import("../main/taxes"),
      }),
    },
    {
      path: "/vendors/:vendorId/microservice-products/taxes/add",
      component: LoadableComponent({
        loader: () => import("../main/add-edit-tax"),
      }),
    },
    {
      path: "/vendors/:vendorId/microservice-products/taxes/:taxId",
      component: LoadableComponent({
        loader: () => import("../main/add-edit-tax"),
      }),
    },
    {
      path: "/vendors/:vendorId/microservice-products/pms-stock/modifier-group/add",
      component: LoadableComponent({
        loader: () => import("../main/microservice-pms/master-stock-list/modifier-group/add-edit-modifier-group"),
      }),
    },
    {
      path: "/vendors/:vendorId/microservice-products/pms-stock/modifier-group/:groupId",
      component: LoadableComponent({
        loader: () => import("../main/microservice-pms/master-stock-list/modifier-group/add-edit-modifier-group"),
      }),
    },
    {
      path: "/vendors/:vendorId/microservice-products/pms-stock/modifier/:modifierId",
      component: LoadableComponent({
        loader: () => import("../main/microservice-pms/master-stock-list/modifiers/add-edit-modifiers"),
      }),
    },
    {
      path: "/vendors/:vendorId/microservice-products/pms-stock/modifier/add",
      component: LoadableComponent({
        loader: () => import("../main/microservice-pms/master-stock-list/modifiers/add-edit-modifiers"),
      }),
    },
    {
      path: "/vendors/:vendorId/microservice-products/stock-location/:stockLocationId/manage-products/modifier/add",
      component: LoadableComponent({
        loader: () => import("../main/microservice-pms/master-stock-list/modifiers/add-edit-modifiers")
      }),
    },
    {
      path: "/vendors/:vendorId/microservice-products/stock-location/:stockLocationId/manage-products/modifier/:modifierId",
      component: LoadableComponent({
        loader: () => import("../main/microservice-pms/master-stock-list/modifiers/add-edit-modifiers")
      }),
    },
    {
      path: "/vendors/:vendorId/microservice-products/stock-location/:stockLocationId/manage-products/modifier-group/add",
      component: LoadableComponent({
        loader: () => import("../main/microservice-pms/master-stock-list/modifier-group/add-edit-modifier-group")
      }),
    },
    {
      path: "/vendors/:vendorId/microservice-products/stock-location/:stockLocationId/manage-products/modifier-group/:groupId",
      component: LoadableComponent({
        loader: () => import("../main/microservice-pms/master-stock-list/modifier-group/add-edit-modifier-group")
      }),
    },
    {
      path: "/vendors/:vendorId/microservice-products/pms-stock/item/add",
      component: LoadableComponent({
        loader: () => import("../main/microservice-pms/master-stock-list/items/add-edit-item-pms"),
      }),
    },
    {
      path: "/vendors/:vendorId/microservice-products/pms-stock/item/edit/:itemId",
      component: LoadableComponent({
        loader: () => import("../main/microservice-pms/master-stock-list/items/add-edit-item-pms"),
      }),
    },
    {
      path: "/vendors/:vendorId/microservice-products/stock-location/:stockLocationId/manage-products/item/add",
      component: LoadableComponent({
        loader: () => import("../main/microservice-pms/master-stock-list/items/add-edit-item-pms")
      }),
    },
    {
      path: "/vendors/:vendorId/microservice-products/stock-location/:stockLocationId/manage-products/item/edit/:itemId",
      component: LoadableComponent({
        loader: () => import("../main/microservice-pms/master-stock-list/items/add-edit-item-pms")
      })
    },
    {
      path: "/vendors/:vendorId/microservice-products/pms-stock/item/bulk-upload",
      component: LoadableComponent({
        loader: () => import("../main/microservice-pms/master-stock-list/items/bulk-upload-items"),
      })
    },
    {
      path: "/vendors/:vendorId/microservice-products/pms-stock/item/add-category",
      component: LoadableComponent({
        loader: () => import("../main/microservice-pms/master-stock-list/items/item-categories"),
      }),
    },
    {
      path: "/vendors/:vendorId/microservice-products/stock-location",
      component: LoadableComponent({
        loader: () => import("../main/microservice-pms/stock-location"),
      }),
    },
    {
      path: "/vendors/:vendorId/microservice-products/stock-location/add",
      component: LoadableComponent({
        loader: () => import("../main/microservice-pms/stock-location/add-edit-stock-location-pms"),
      }),
    },
    {
      path: "/vendors/:vendorId/microservice-products/stock-location/edit/:stockLocationId",
      component: LoadableComponent({
        loader: () => import("../main/microservice-pms/stock-location/add-edit-stock-location-pms"),
      }),
    },
    {
      path: "/vendors/:vendorId/microservice-products/stock-location/:stockLocationId/assign-managers",
      component: LoadableComponent({
        loader: () => import("../main/microservice-pms/stock-location/assign-managers"),
      }),
    },
    {
      path: "/vendors/:vendorId/microservice-products/stock-location/:stockLocationId/manage-products",
      component: LoadableComponent({
        loader: () => import("../main/microservice-pms/stock-location/manage-products")
      }),
    },
    {
      path: "/vendors/:vendorId/microservice-products/pms-stock",
      component: LoadableComponent({
        loader: () => import("../main/microservice-pms/master-stock-list"),
      }),
    },
    {
      path: "/vendors/:vendorId/microservice-products/suppliers-po",
      component: LoadableComponent({
        loader: () => import("../main/microservice-pms/suppliers-po"),
      }),
    },
    {
      path: "/vendors/:vendorId/microservice-products/suppliers-po/suppliers/add",
      component: LoadableComponent({
        loader: () => import("../main/microservice-pms/suppliers-po/suppliers/add-edit-supplier"),
      }),
    },
    {
      path: "/vendors/:vendorId/microservice-products/suppliers-po/suppliers/edit/:supplierId",
      component: LoadableComponent({
        loader: () => import("../main/microservice-pms/suppliers-po/suppliers/add-edit-supplier"),
      }),
    },
    {
      path: "/vendors/:vendorId/microservice-products/stock-location/:stockLocationId/manage-products/item/bulk-upload",
      component: LoadableComponent({
        loader: () => import("../main/microservice-pms/master-stock-list/items/bulk-upload-items"),
      }),
    },
    {
      path: "/vendors/:vendorId/microservice-products/stock-location/:stockLocationId/manage-stock",
      component: LoadableComponent({
        loader: () => import("../main/microservice-pms/stock-actions"),
      }),
    },
    {
      path: "/vendors/:vendorId/microservice-products/stock-location/:stockLocationId/stock-count/:stockCountId",
      component: LoadableComponent({
        loader: () => import("../main/view-pms-stock-count"),
      }),
    },
    {
      path: "/vendors/:vendorId/microservice-products/stock-actions/stock-count/:stockCountId",
      component: LoadableComponent({
        loader: () => import("../main/view-pms-stock-count"),
      }),
    },
    {
      path: "/vendors/:vendorId/microservice-products/stock-location/:stockLocationId/stock-adjustment/:stockCountId",
      component: LoadableComponent({
        loader: () => import("../main/view-pms-stock-adjustment"),
      }),
    }, 
    {
      path: "/vendors/:vendorId/microservice-products/stock-actions/stock-adjustment/:stockCountId",
      component: LoadableComponent({
        loader: () => import("../main/view-pms-stock-adjustment"),
      }),
    },
    {
      path: "/vendors/:vendorId/microservice-products/stock-location/:stockLocationId/stock-transfer/:stockTransferId",
      component: LoadableComponent({
        loader: () => import("../main/view-pms-stock-transfer"),
      }),
    }, 
    {
      path: "/vendors/:vendorId/microservice-products/stock-actions/stock-transfer/:stockTransferId",
      component: LoadableComponent({
        loader: () => import("../main/view-pms-stock-transfer"),
      }),
    },
    {
      path: "/vendors/:vendorId/microservice-products/stock-actions",
      component: LoadableComponent({
        loader: () => import("../main/microservice-pms/stock-actions"),
      }),
    },
    {
      path: "/vendors/:vendorId/microservice-products/suppliers-po/add-edit-purchase-order/:isBackdate",
      component: LoadableComponent({
        loader: () => import("../main/microservice-pms/suppliers-po/purchase-orders/add-edit-purchase-order"),
      }),
    },
    {
      path: "/vendors/:vendorId/microservice-products/suppliers-po/add-edit-purchase-order/:isBackdate/:supplierId/:stockLocationId",
      component: LoadableComponent({
        loader: () => import("../main/microservice-pms/suppliers-po/purchase-orders/add-edit-purchase-order/EditPurchaseOrder"),
      }),
    },
    {
      path: "/vendors/:vendorId/microservice-products/suppliers-po/add-edit-purchase-order/:isBackdate/:supplierId",
      component: LoadableComponent({
        loader: () => import("../main/microservice-pms/suppliers-po/purchase-orders/add-edit-purchase-order/EditPurchaseOrder"),
      }),
    },
    {
      path: "/vendors/:vendorId/microservice-products/suppliers-po/purchaseOrder/:purchaseOrderId/:isBackdate",
      component: LoadableComponent({
        loader: () => import("../main/microservice-pms/suppliers-po/purchase-orders/add-edit-purchase-order/EditPurchaseOrder"),
      }),
    },
    {
      path: "/vendors/:vendorId/microservice-products/suppliers-po/purchaseOrder/:purchaseOrderId/:isBackdate",
      component: LoadableComponent({
        loader: () => import("../main/microservice-pms/suppliers-po/purchase-orders/add-edit-purchase-order/EditPurchaseOrder"),
      }),
    },
    {
      path: "/vendors/:vendorId/microservice-products/suppliers-po/purchaseOrder/:purchaseOrderId/view-products/:isBackdate",
      component: LoadableComponent({
        loader: () => import("../main/microservice-pms/suppliers-po/purchase-orders/add-edit-purchase-order/EditPurchaseOrder"),
      }),
    },
    {
      path: "/vendors/:vendorId/microservice-products/suppliers-po/:supplierId/view-products",
      component: LoadableComponent({
        loader: () => import("../main/microservice-pms/suppliers-po/suppliers/view-products"),
      }),
    },
    {
      path: "/vendors/:vendorId/microservice-products/stock-location/:stockLocationId/purchaseOrder/:purchaseOrderId/view-products",
      component: LoadableComponent({
        loader: () => import("../main/microservice-pms/suppliers-po/purchase-orders/add-edit-purchase-order/EditPurchaseOrder"),
      }),
    },
    {
      path: "/vendors/:vendorId/microservice-products/stock-actions/purchaseOrder/:purchaseOrderId/view-products",
      component: LoadableComponent({
        loader: () => import("../main/microservice-pms/suppliers-po/purchase-orders/add-edit-purchase-order/EditPurchaseOrder"),
      }),
    },
    {
      path: "/vendors/:vendorId/outlets/:outletId/assign-printer-kds",
      component: LoadableComponent({
        loader: () => import("../main/assign-printer-kds"),
      })
    },
    {
      path: "/vendors/:vendorId/microservice-products/pms-stock/ingredients/add",
      component: LoadableComponent({
        loader: () => import("../main/microservice-pms/master-stock-list/ingredients/add-edit-ingredients"),
      }),
    },
    {
      path: "/vendors/:vendorId/microservice-products/pms-stock/ingredients/:ingredientId",
      component: LoadableComponent({
        loader: () => import("../main/microservice-pms/master-stock-list/ingredients/add-edit-ingredients"),
      }),
    },
    {
      path: "/vendors/:vendorId/microservice-products/stock-location/:stockLocationId/manage-products/ingredients/add",
      component: LoadableComponent({
        loader: () => import("../main/microservice-pms/master-stock-list/ingredients/add-edit-ingredients")
      }),
    },
    {
      path: "/vendors/:vendorId/microservice-products/stock-location/:stockLocationId/manage-products/ingredients/:ingredientId",
      component: LoadableComponent({
        loader: () => import("../main/microservice-pms/master-stock-list/ingredients/add-edit-ingredients")
      })
    },
    {
      path: "/vendors/:vendorId/microservice-products/pms-stock/ingredients/add-category",
      component: LoadableComponent({
        loader: () => import("../main/microservice-pms/master-stock-list/ingredients/ingredients-category"),
      }),
    },
    {
      path: "/vendors/:vendorId/microservice-products/pms-stock/ingredients/bulk-upload",
      component: LoadableComponent({
        loader: () => import("../main/microservice-pms/master-stock-list/ingredients/bulk-upload-ingredients"),
      })
    },
    {
      path: "/vendors/:vendorId/microservice-products/stock-location/:stockLocationId/manage-products/ingredients/bulk-upload",
      component: LoadableComponent({
        loader: () => import("../main/microservice-pms/master-stock-list/ingredients/bulk-upload-ingredients")
      }),
    },
    {
      path: "/vendors/:vendorId/microservice-products/pms-stock/set-alerts",
      component: LoadableComponent({
        loader: () => import("../main/microservice-pms/set-stock-email-alert"),
      }),
    },
    {
      path: "/vendors/:vendorId/microservice-products/stock-location/:stockLocationId/set-alerts",
      component: LoadableComponent({
        loader: () => import("../main/microservice-pms/set-stock-email-alert"),
      }),
    },
    {
      path: "/vendors/:vendorId/microservice-products/menus",
      component: LoadableComponent({
        loader: () => import("../main/microservice-pms/menu/menu-list"),
      }),
    },
    {
      path: "/vendors/:vendorId/microservice-products/menus/bulk-upload-menu",
      component: LoadableComponent({
        loader: () => import("../main/microservice-pms/menu/bulk-upload-menu"),
      }),
    },
    {
      path: "/vendors/:vendorId/microservice-products/menus/:menuId/edit",
      component: LoadableComponent({
        loader: () => import("../main/microservice-pms/menu/add-edit-menu"),
      }),
    },
    {
      path: "/vendors/:vendorId/microservice-products/menus/add",
      component: LoadableComponent({
        loader: () => import("../main/microservice-pms/menu/add-edit-menu"),
      }),
    },
    {
      path: "/vendors/:vendorId/microservice-products/menus/:menuId/add-items",
      component: LoadableComponent({
        loader: () => import("../main/microservice-pms/menu-detail-list"),
      }),
    },
    {
      path: "/vendors/:vendorId/microservice-products/menus/:menuId/edit/prepaid-menu-email-listing",
      component: LoadableComponent({
        loader: () => import("../main/microservice-pms/menu/prepaid-menu-email-list"),
      }),
    },
    {
      path: "vendors/:vendorId/reports/sales-summary",
      component: LoadableComponent({
        loader: () => import("../main/sales-summary-new"),
      }),
    },
    {
      path: "vendors/:vendorId/reports/sales-summary/store-report",
      component: LoadableComponent({
        loader: () => import("../main/sales-summary-new/store-report"),
      }),
    },
    {
      path: "vendors/:vendorId/reports/sales-summary/site-report",
      component: LoadableComponent({
        loader: () => import("../main/sales-summary-new/site-report"),
      }),
    },
    {
      path: "vendors/:vendorId/reports/sales-summary/invited-site-report",
      component: LoadableComponent({
        loader: () => import("../main/sales-summary-new/invited-site-report"),
      }),
    },
    {
      path: "/vendors/:vendorId/sites/:siteId/charge/add",
      component: LoadableComponent({
        loader: () => import("../main/add-edit-charge"),
      }),
    },
    {
      path: "/vendors/:vendorId/sites/:siteId/charge/edit/:chargeId",
      component: LoadableComponent({
        loader: () => import("../main/add-edit-charge"),
      }),
    },
    {
      path: "/vendors/:vendorId/sites/:siteId/transfer/add",
      component: LoadableComponent({
        loader: () => import("../main/add-transfer"),
      }),
    },
  ],
};
