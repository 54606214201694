import { network } from "../common/network";
import { notify } from "../common/notify";
import { siteServiceTypesProps } from "../components/types";
import apiConsts from "../constants/apiConstants";

class SiteService {
  public async getAll(params: any) {
    let query: any = {
      page: params.paginate.current - 1,
      rowsPerPage: params.paginate.pageSize,
      searchText: params.searchText,
      isArchived: params.isArchived,
      characterFilter: params.characterFilter,
      isReportRequest: params.isReportRequest || false,
      sortBy: params.sorter ? { [params.sorter.columnKey]: params.sorter.order === "ascend" ? 1 : params.sorter.order === "descend" ? -1 :  params.sorter.order}: {},
      category: params.category ? params.category : ""
    };
    if (params.filters && params.filters.status) {
      query.status = JSON.stringify(params.filters.status);
    }
    if (params.filters && params.filters.category) {
      query.category = JSON.stringify(params.filters.category);
    }
    const response = await network(
      `${apiConsts.vendorURI}/${params.extras.vendorId}/site`,
      query
    );
    if (response?.success)
      return {
        data: response?.data,
        totalRecord: response?.totalRecord,
      };
    return { data: [], totalRecord: 0 };
  }

  public async getZones(vendorId: string, siteId: string, query={}) {
    const uri = `${apiConsts.vendorOutletURI}/${vendorId}/site/${siteId}/site-zones`;
    const response = await network(uri, query, "GET");
    if (response?.success) {
      return response?.data;
    }
    return { data: [], totalRecord: 0 };
  }

  public async deleteSite(vendorId: string, siteId: string) {
    const response =  await network(
      `/v2/vendors/${vendorId}/site/${siteId}`,
      undefined,
      "DELETE",
    );
    if (response?.success) {
      notify(
        "success",
        "Selected Site removed. This action cannot be undone!"
      );
      return response;
    }
    notify("error", response?.message);
    throw new Error(response?.message);
  }

  public async archiveOutlet(
    vendorId: string | undefined,
    siteId: string | undefined
  ) {
    const uri = `${apiConsts.vendorURI}/${vendorId}/site/archive/${siteId}`;
    const response = await network(uri, undefined, "DELETE");
    if (response?.success) {
      notify("success", "Selected Site archived.");
      return response;
    }
    notify("error", response?.message);
    throw new Error(response?.message);
  }

  public async unarchiveOutlet(
    vendorId: string | undefined,
    siteId: string | undefined
  ) {
    const uri = `${apiConsts.vendorURI}/${vendorId}/site/unarchive/${siteId}`;
    const response = await network(uri, undefined, "DELETE");
    if (response?.success) {
      notify("success", "Selected site unarchived.");
      return response;
    }
    notify("error", response?.message);
    throw new Error(response?.message);
  }

  public async create(vendorId: string, payload: any) {
    const response = await network(
      `${apiConsts.vendorURI}/${vendorId}/site/`,
      payload,
      "POST"
    );
    if (response?.success) {
      notify("success", "Site created successfully!");
      return response;
    }
    notify("error", response?.message);
    throw response;
  }
  public async update(vendorId: string, siteId: string, payload: any) {
    const response = await network(
      `${apiConsts.vendorURI}/${vendorId}/site/${siteId}`,
      payload,
      "PUT"
    );
    if (response?.success) {
      notify("success", "Site updated successfully!");
      return response;
    }
    notify("error", response?.message);
    throw response;
  }

  public async reject(vendorId: string, siteId: string, payload: any) {
    const uri = `${apiConsts.vendorURI}/${vendorId}/site/${siteId}/site-reject`;
    const response = await network(uri, payload, "PUT");
    if (response?.success) {
      notify("success", "Site rejected successfully!");
      return response;
    }
    notify("error", response?.message);
    throw response;
  }

  /*****************************************************************************/
  public async getOne(vendorId: string, siteId: string) {
    const response = await network(
      `${apiConsts.vendorURI}/${vendorId}/site/${siteId}`,
      {},
      "GET"
    );
    if (response?.success) {
      return response;
    }
    throw response;
  }

  public async duplicate(
    vendorId: string | undefined,
    siteId: string | undefined,
    payload: any
  ) {
    const response = await network(
      `${apiConsts.vendorURI}/${vendorId}/site/duplicate/${siteId}`,
      payload,
      "POST"
    );
    if (response?.success) {
      notify("success", "site duplicated successfully!");
      return response;
    }
    notify("error", response?.message);
    throw response;
  }

  // -----------------------------ZONES-----------------------
  public async getZoneData(
    vendorId: string,
    siteId: string,
    searchText: string
  ) {
    const response = await network(
      `/v2/${apiConsts.vendorURI}/${vendorId}/site/${siteId}/site-zones`,
      {
        searchText: searchText.trim(),
      }
    );
    return response?.data || [];
  }
  /***********************************************************************Outlets*/
  public async getAssignedOutlets(vendorId: string, outletId: string) {
    const response = await network(
      `${apiConsts.vendorURI}/${vendorId}/outlet/${outletId}/site/assigned-sites`,
      {},
      "GET"
    );
    return response;
  }

  public async assignOutlets(
    vendorId: string,
    siteId: string,
    payload: {
      outletId: string;
      siteZoneId?: string;
    }
  ) {
    const response = await network(
      `${apiConsts.vendorURI}/${vendorId}/site/assign/${siteId}`,
      payload,
      "PUT"
    );
    if(response?.success){
      return response;
    }
    throw new Error(response?.message);
  }

  public async unAssignOutlets(
    vendorId: string,
    siteId: string,
    payload: {
      outletId: string;
    }
  ) {
    const response = await network(
      `${apiConsts.vendorURI}/${vendorId}/site/unassign/${siteId}`,
      payload,
      "PUT"
    );
    response.success
      ? notify("success", "Store unassigned to Site")
      : notify("error", response.message);
    return response;
  }
  public async getAllOutlets(vendorId: string, query: string) {
    const response = await network(
      `${apiConsts.vendorURI}/${vendorId}${apiConsts.outletURI}`,
      {
        searchText: query,
      },
      "GET"
    );
    return response?.data || [];
  }

  public async getAllActiveOutlets(vendorId:string, query: any){
    const response = await network(
      `${apiConsts.vendorURI}/${vendorId}${apiConsts.outletURI}/search`,
      query,
      "GET"
    );
    return response?.data || [];
  }

  // ------------  ZONES ------------ //
  public async getZoneList(vendorId: string, siteId: string) {
    const uri = `${apiConsts.vendorURI}/${vendorId}/site/${siteId}/site-zones`;
    const response = await network(uri, {});
    return response?.data || [];
  }

  public async createZone(vendorId: string, siteId: string, name: any) {
    const uri = `${apiConsts.vendorURI}/${vendorId}/site/${siteId}/site-zones`;
    const response = await network(uri, name, "POST", {});
    if (response?.success) {
      notify("success", "Zone is added successfully.");
    } else {
      notify("error", response?.message);
      throw response;
    }
  }

  public async deleteZone(vendorId: string, siteId: string, zoneId: string) {
    const uri = `${apiConsts.vendorURI}/${vendorId}/site/${siteId}/site-zones/delete/${zoneId}`;
    const response = await network(uri, undefined, "DELETE");
    if (response?.success) {
      notify("success", "Zone is removed successfully.");
    } else {
      notify("error", response?.message);
      throw response;
    }
  }
  public async getSiteBranding(vendorId: string | undefined, siteId: string | undefined) {
    const response = await network(`/v2/vendors/${vendorId}/site/${siteId}/branding`,{},"GET");
    return response?.data;
  }
  public async updateSiteBranding(vendorId: string | undefined, siteId: string | undefined, newSettings: any) {
    const response = await network(
      `/v2/vendors/${vendorId}/site/${siteId}/branding`, 
      newSettings, 
      "PUT",
      {"ContentType":"multipart/form-data"}
    );
    if (response?.success) {
      notify("success", "Site Branding updated successfully!")
        
        } else {
      notify("error", "Site Branding updation failed.")
        }
        return response
  }

  public async getSiteSettings (vendorId: string, siteId: string ){
    const uri = `${apiConsts.vendorURI}/${vendorId}/site/${siteId}/settings`;
    const response = await network(uri, {}, "GET");
    if(response?.success){
      return response?.data
    }
    notify("error", response.message);
  }

  public async updateSiteServiceTypeStatus(params: {
    vendorId: string;
    siteId: string;
    serviceType: siteServiceTypesProps;
    isActive: boolean;
  }){
    const uri = `${apiConsts.vendorURI}/${params.vendorId}/site/${params.siteId}/${params.serviceType}/${params.isActive}`;
    const response = await network(uri, {}, "PUT");
    if(response?.success){
      notify("success", "Settings updated successfully!");
      return
    }
    notify("error", response.message);
  }

  public async updateOnsiteDeliveryServiceTypeSettings(vendorId: string, siteId: string, params: any){
    const uri = `${apiConsts.vendorURI}/${vendorId}/site/${siteId}/on-site-delivery`;
    const response = await network(uri, params, "PUT");
    if(response?.success){
      notify("success", "Settings updated successfully!");
      return response;
    }
    notify("error", response?.message);
  }

  public async updateOnsiteCollectionSettings(vendorId:string, siteId: string, params: any){
    const uri = `${apiConsts.vendorURI}/${vendorId}/site/${siteId}/on-site-collection`;
    const response = await network(uri, params, "PUT");
    if(response?.success){
      notify("success", "Settings updated successfully!");
      return response;
    }
    notify("error", response?.message);
  }
  public async updateOnsiteExpressSettings(vendorId:string, siteId: string, params: any){
    const uri = `${apiConsts.vendorURI}/${vendorId}/site/${siteId}/on-site-express`;
    const response = await network(uri, params, "PUT");
    if(response?.success){
      notify("success", "Settings updated successfully!");
      return response;
    }
    notify("error", response?.message);
  }
  public async saveStoresSettings (vendorId: string, siteId: string, data: any ){
    const uri = `${apiConsts.vendorURI}/${vendorId}/site/${siteId}/outlets-services`;
    const response = await network(uri, data, "PUT");
    if(response?.success){
      notify("success", "Store Settings updated successfully!");
      return response?.data
    }
    notify("error", response.message);
  }
  public async toggleServiceTypeAutoAccept(vendorId: string, siteId: string, serviceType:siteServiceTypesProps, params:any){
    const uri = `${apiConsts.vendorURI}/${vendorId}/site/${siteId}/${serviceType}/auto-accept`;
    const response = await network(uri, params, "PUT");
    if(response?.success){
      notify("success", "Settings updated successfully!");
      return response?.data;
    }
    notify("error", response?.message);
  }
  public async getAssignedOutletsBySearch(vendorId: string, siteId: string, params: any){
    const uri = `${apiConsts.vendorURI}/${vendorId}/site/${siteId}/assigned-outlets`;
    const response = await network(uri, params, "GET");
    if(response?.success){
      return response?.data;
    }
    notify("error", response.message);
  }
  public async inviteExternalBusiness(vendorId: string, siteId: string, params: any){
    const uri = `${apiConsts.vendorURI}/${vendorId}/site/${siteId}/invite-business/initiation`;
    const response = await network(uri, params, "POST");
    if(response?.success){
      notify("success", "Business invited successfully!")
      return response?.data;
    }
    notify("error", response.message);
  }
  public async getInvitedBusinesses(vendorId: string, siteId: string, params: any){
    const uri = `${apiConsts.vendorURI}/${vendorId}/site/${siteId}/invite-business/list`;
    const response = await network(uri, params, "GET");
    if(response?.success){
      return response?.data;
    }
    notify("error", response?.message);
    return;
  }
  public async getInvitedBusinessDetails(vendorId: string, siteId: string, invitedBusinessId: string){
    const uri = `${apiConsts.vendorURI}/${vendorId}/site/${siteId}/invite-business/${invitedBusinessId}`;
    const response = await network(uri, {}, "GET");
    if(response?.success){
      return response?.data;
    }
    notify("error", response?.message);
    return;
  }
  public async updateBusinessInvitation(vendorId: string, siteId: string, invitedBusinessId: string, params: any){
    const uri = `${apiConsts.vendorURI}/${vendorId}/site/${siteId}/invite-business/${invitedBusinessId}`;
    const response = await network(uri, params, "PUT");
    if(response?.success){
      notify("success", "Invitation updated successfully!")
      return response?.data
    }
    notify("error", response?.message);
    return;
  }
  public async archiveExternalBusiness(vendorId: string, siteId: string, invitedBusinessId: string){
    const uri = `${apiConsts.vendorURI}/${vendorId}/site/${siteId}/invite-business/${invitedBusinessId}/archive`;
    const response = await network(uri, {}, "PUT");
    if(response?.success){
      notify("success", "Selected business archived successfully! Please verify the split arrangements.");
      return response?.data;
    }
    notify("error", response?.message);
    return
  }
  public async unarchiveExternalBusiness(vendorId: string, siteId: string, invitedBusinessId:string){
    const uri = `${apiConsts.vendorURI}/${vendorId}/site/${siteId}/invite-business/${invitedBusinessId}/unarchive`;
    const response = await network(uri, {}, "PUT");
    if(response?.success){
      notify("success", "Selected business unarchived successfully! Please verify the split arrangements.");
      return response?.data;
    }
    notify("error", response?.message);
    return
  }
  public async sendReminderToExternalBusiness(vendorId: string, siteId: string, invitedBusinessId: string, params:any){
    const uri = `${apiConsts.vendorURI}/${vendorId}/site/${siteId}/invite-business/${invitedBusinessId}/reminder`;
    const response = await network(uri, params, "PUT");
    if(response?.success){
      notify("success", "Reminder sent successfully!");
      return;
    }
    notify("error", response?.message);
    return;
  }
  public async sendReminderToStoreBusiness(vendorId: string, siteId: string, arrangementId: string, storeId: string, params:any){
    const uri = `${apiConsts.vendorURI}/${vendorId}/site/${siteId}/split-arrangement/${arrangementId}/store/${storeId}/reminder`;
    const response = await network(uri, params, "PUT");
    if(response?.success){
      notify("success", "Reminder sent successfully!");
      return;
    }
    notify("error", response?.message);
    return;
  }
  public async createSplitArrangement(vendorId: string, siteId: string, params: any){
    const uri = `${apiConsts.vendorURI}/${vendorId}/site/${siteId}/split-arrangement/create`;
    const response = await network(uri, params, "POST");
    if(response?.success){
      notify("success", "Split arrangement created successfully!");
      return response?.data
    }
    notify("error", response?.message);
    return
  }
  public async getAllSplitArrangements(vendorId: string, siteId: string){
    const uri = `${apiConsts.vendorURI}/${vendorId}/site/${siteId}/split-arrangement`;
    const response = await network(uri, {}, "GET");
    if(response?.success){
      return response?.data;
    }
    notify("error", response.message);
    return;
  }
  public async removeSplitArrangement(vendorId: string, siteId: string, arrangementId: string){
    const uri = `${apiConsts.vendorURI}/${vendorId}/site/${siteId}/split-arrangement/${arrangementId}`;
    const response = await network(uri, {}, "DELETE");
    if(response?.success){
      notify("success", "Selected item removed successfully!");
      return;
    }
    notify("error", response?.message);
    return;
  }
  public async getSplitArrangementDetail(vendorId: string, siteId: string, splitId:string){
    const uri = `${apiConsts.vendorURI}/${vendorId}/site/${siteId}/split-arrangement/${splitId}`;
    const response = await network(uri, {}, "GET");
    if(response?.success){
      return response?.data;
    }
    notify("error", response?.message);
    return;
  }
  public async updateSplitArrangement(vendorId: string, siteId: string, splitId: string, params:any){
    const uri = `${apiConsts.vendorURI}/${vendorId}/site/${siteId}/split-arrangement/${splitId}`;
    const response = await network(uri, params, "PUT");
    if(response?.success){
      notify("success", "Split arrangement updated successfully!");
      return response?.data
    }
    notify("error", response?.message);
    return
  }

  public async archiveAssignedStoreAtSiteLevel(vendorId: string, siteId: string, storeId: string){
    const uri = `${apiConsts.vendorURI}/${vendorId}/site/${siteId}/archive-store/${storeId}`;
    const response = await network(uri, {}, "PUT");
    if(response?.success){
      notify("success", "Store archived successfully. Please verify the split arrangement.");
      return;
    }
    notify("error", response?.message);
    return;
  }

  public async unarchiveAssignedStoreAtSiteLevel(vendorId: string, siteId: string, storeId: string){
    const uri = `${apiConsts.vendorURI}/${vendorId}/site/${siteId}/unArchive-store/${storeId}`;
    const response = await network(uri, {}, "PUT");
    if(response?.success){
      notify("success", "Store unarchived successfully. Please verify the split arrangement.");
      return;
    }
    notify("error", response?.message);
    return;
  }

  public async fetchUnassignedStoresForSplit(vendorId: string, siteId: string, params: any){
    const uri = `${apiConsts.vendorURI}/${vendorId}/site/${siteId}/split-arrangement/unassigned-store`;
    const response = await network(uri, params, "GET");
    if(response?.success){
      return response?.data;
    }
    notify("error", response?.message);
    return;
  }

  public async assignStoreToSplitArrangement(vendorId: string, siteId: string, splitArrangementId: string, storeId: string){
    const uri = `${apiConsts.vendorURI}/${vendorId}/site/${siteId}/split-arrangement/${splitArrangementId}/store/${storeId}`;
    const response = await network(uri, {}, "POST");
    if(response?.success){
      notify("success", "Store assigned successfully!");
      return response?.data;
    }
    notify("error", response?.message);
    return;
  }

  public async getAssignedStoresToSplitArrangement(vendorId: string, siteId: string,splitArrangementId: string, isArchived = false){
    const uri = `${apiConsts.vendorURI}/${vendorId}/site/${siteId}/split-arrangement/${splitArrangementId}/assigned-stores`;
    const response = await network(uri, {isArchived}, "GET");
    if(response?.success){
      return response?.data;
    }
    notify("error", response?.message);
    return;
  }

  public async unassignStoreFromSplitArrangement(vendorId: string, siteId:string, splitArrangementId:string, storeId: string){
    const uri = `${apiConsts.vendorURI}/${vendorId}/site/${siteId}/split-arrangement/${splitArrangementId}/store/${storeId}`;
    const response = await network(uri, undefined, "DELETE");
    if(response?.success){
      notify("success", "Store removed successfully!");
      return;
    }
    notify("error", response?.message);
    return;
  }

  public async archiveUnarchiveStoreFromSplit(vendorId: string, siteId:string, splitArrangementId:string, storeId: string, isArchived: boolean){
    const uri = `${apiConsts.vendorURI}/${vendorId}/site/${siteId}/split-arrangement/${splitArrangementId}/store/${storeId}/update-store`;
    const response = await network(uri, {isArchived}, "PUT");
    if(response?.success){
      notify("success", isArchived ? "Store archived successfully!" : "Store unarchived successfully");
      return;
    }
    notify("error", response?.message);
    return;
  }

  public async removeAssignedStoreFromSite(vendorId: string, siteId: string, storeId:string){
    const uri = `${apiConsts.vendorURI}/${vendorId}/site/${siteId}/delete-store/${storeId}`;
    const response = await network(uri, {}, "PUT");
    if(response?.success){
      notify("success", "Store removed successfully!");
      return;
    }
    notify("error", response?.message);
    throw response;
  }

  public async ownedSiteExternalStoreDetails(vendorId: string, siteId: string, storeId: string){
    const uri = `${apiConsts.vendorURI}/${vendorId}/site/${siteId}/split-arrangement/store/${storeId}`;
    const response = await network(uri, {}, "GET");
    if(response?.success){
      return response?.data;
    }
    notify("error", response?.message);
    return;
  }

  public async getAssignedPromotions(vendorId: string, siteId:string){
    const uri = `${apiConsts.vendorURI}/${vendorId}/site/${siteId}/promotions`;
    const response = await network(uri, undefined);
    if(response?.success){
      return response;
    }
    notify("error", response?.message);
    return;
  }

  public async assignPromotionToSite(vendorId: string, siteId: string, promotionRef: String){
    const uri = `${apiConsts.vendorURI}/${vendorId}/site/${siteId}/promotions/${promotionRef}`;
    const response = await network(uri, undefined, 'POST');
    if(response?.success){
      notify("success", "Promotion Assigned!");
      return response;
    }
    notify("error", response?.message);
    throw new Error(response?.message);
  }

  public async removePromotionFromSite(vendorId: string, siteId: string, promotionRef: string){
    const uri = `${apiConsts.vendorURI}/${vendorId}/site/${siteId}/promotions/${promotionRef}`;
    const response = await network(uri, undefined, 'DELETE');
    if(response?.success){
      notify("success", "Promotion Removed!");
      return;
    }
    notify("error", response?.message);
    return;
  }

  public async rejectSplitArrangementChange(vendorId: string, siteId: string, splitArrangementId:string, params:any){
    const uri = `${apiConsts.vendorURI}/${vendorId}/site/${siteId}/split-arrangement/${splitArrangementId}/reject-arrangement-change`;
    const response = await network(uri, params, "PUT");
    if(response?.success){
      notify("success", "Split arrangement rejected successfully!");
      return;
    }
    notify("error", response?.message);
    return;
  }

  public async getAvailableUnassignedStores(vendorId: string, params: any){
    const uri = `${apiConsts.vendorURI}/${vendorId}/outlet/not-assigned-to-sites`;
    const response = await network(uri, params, "GET");
    return response?.data || [];
  }

  public async checkIfExternalStoresAvailableInAnySite(vendorId: string){
    const uri = `${apiConsts.vendorURI}/${vendorId}/external-business-access-status`;
    const response = await network(uri, {}, "GET");
    if(response?.success){
      return response?.data;
    }
    notify("error", response?.message);
    return;
  }

  public async getSitesListWithExternalStores(vendorId: string, params: any){
    const uri = `${apiConsts.vendorURI}/${vendorId}/site/sites-with-invited-stores`;
    const response = await network(uri, params, "GET");
    if(response?.success){
      return response?.data;
    }
    notify("error", response?.message);
    return;
  }

  public async getExternalStoresBySiteId(vendorId: string, siteId: string){
    const uri = `${apiConsts.vendorURI}/${vendorId}/site/${siteId}/invited-outlets`;
    const response = await network(uri, {}, "GET");
    if(response?.success){
      return response?.data;
    }
    notify("error", response?.message);
    return;
  }

  public async getBalanceAccBySiteId(vendorId: string, siteId: string){
    const uri = `${apiConsts.vendorURI}/${vendorId}/site/${siteId}/invited-business-ba`;
    const response = await network(uri, {}, "GET");
    if(response?.success){
      return response?.data;
    }
    notify("error", response?.message);
    return;
  }
}

export const sitesService = new SiteService();
