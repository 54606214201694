import { useState } from "react";
import { useFormik } from "formik";
import { Row, Form, Input, Typography } from "antd";
import "../../App.less";
import { StyledLink } from "../utils/StyledLink";
import * as yup from "yup";
const { Text, Title } = Typography;
import jwtService from "../../services/jwtService";
import logo from "../../assets/images/new_logo.png";
import { useBreakpoints } from "../hooks/useBreakpoints";
import { useDispatch } from "react-redux";
import { updateUser } from "../../store/actions";
import { notify } from "../../common/notify";
import { useNavigate } from "react-router-dom";
import { CustomButtonLoader } from "../custom-components/custom-buttom-with-loader";
import { AppUserType } from "../../constants/AppConstant";

const Login = () => {
  const [isLoading, setIsLoading] = useState(true);
  const { height, width } = useBreakpoints();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const loginSchema = yup.object().shape({
    email: yup
      .string()
      .email("Must be a valid Email!").trim()
      .required()
      .max(255).label("Email"),
    pswd: yup.string().required().trim().label("Password").max(255),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      pswd: "",
    },
    validationSchema: loginSchema,
    onSubmit: async (values) => {
      setIsLoading(false);
      await jwtService
        .signInWithEmailAndPassword(values.email, values.pswd)
        .then((res: any) => {
          dispatch(updateUser(res));
          navigate(res.userType === AppUserType.ADMIN ? "/manage/vendors" : res.userType === AppUserType.VENDOR ? "/business-home"  : "/profile", { replace: true });
        })
        .catch((response) => {
          notify("error", "Invalid Email or Password!");
        });
      setIsLoading(true);
    },
  });

  return (
    <div className="login">
      <div className="login-form">
        <div>{height > 400 && width > 400 ? <Title level={2}>WELCOME TO</Title> : <></>}</div>
        <div>
          {height > 400 && width > 400 ? (
            <img src={logo} alt="logo" />
          ) : (
            <></>
          )}
        </div>
        <div className="mt-1">
          <h3>LOG IN TO YOUR ACCOUNT</h3>
        </div>
        <div className="w-100">
          <Form
            onFinish={formik.handleSubmit}
            name="basic"
            autoComplete="off"
          >
            <Row>
              {/* <CustomFormGroup labelName='Email' placeholder='jhon.doe@gmail.com' 
                    onChange={(val)=>formik.setFieldValue('email',val)}/> */}
              <label>
                <h3>Email</h3>
              </label>
            </Row>
            <Row>
              <Input
                {...formik.getFieldProps("email")}
                size="large"
                placeholder="jhon.doe@noq.staging"
              />
            </Row>
            <div>
              {formik.touched.email && formik.errors.email ? (
                <Text type="danger">{formik.errors.email}</Text>
              ) : null}
            </div>
            <Row>
              <label className="mt-1">
                <h3>Password</h3>
              </label>
            </Row>
            <Row>
              <Input.Password
                {...formik.getFieldProps("pswd")}
                size="large"
                placeholder="********"
              />
              <div>
                {formik.touched.pswd && formik.errors.pswd ? (
                  <Text type="danger">{formik.errors.pswd}</Text>
                ) : null}
              </div>
            </Row>
            <div className="mt-1">
              <CustomButtonLoader isLoading={!isLoading} type="primary" size="large" htmlType="submit" isBlock={true} btnText="LOGIN" />
            </div>
          </Form>
        </div>
        <div className="mt-1">
          <StyledLink to="/forgot-password">
            <Title type="secondary" level={4}>Forgot Password?</Title>
          </StyledLink>
        </div>
      </div>
    </div>
  );
};

export default Login;
