import {
  QrcodeOutlined,
  UserOutlined,
  ShopOutlined,
  TagFilled,
  UsergroupAddOutlined,
  GiftFilled,
  FileFilled,
  FileExclamationFilled,
  UnorderedListOutlined,
  UsergroupDeleteOutlined,
  NotificationFilled,
  FileTextOutlined,
  BarChartOutlined,
  MobileOutlined,
  LinkOutlined,
  HomeOutlined,
  LineChartOutlined,
  TagsOutlined,
  AppstoreOutlined,
  GlobalOutlined,
  ClusterOutlined,
  TagOutlined,
} from '@ant-design/icons';
import { ReactNode } from 'react';
import { AppUserType } from '../../constants/AppConstant';
import authRoles from '../../constants/authConstants';
import { Vendor, VendorProfileResponse } from '../../constants/interface';
import { managerService } from '../../services/ManagerService';

export interface NavigationConfigChildInterface {
  label: string;
  id: string;
  icon: ReactNode;
  path: string;
  child?: string[];
  childItems?: Array<any>; // To Remove
  children?: Array<any>;
  type?: string;
}
export interface NavigationConfigInterface {
  id: string;
  auth: string[];
  children: NavigationConfigChildInterface[];
}
const navigationConfig: NavigationConfigInterface[] = [
  {
    id: 'admin',
    auth: authRoles.admin, //[ADMIN]
    children: [
      {
        label: 'Administrators',
        id: 'admins',
        icon: UsergroupDeleteOutlined,
        path: '/manage/admins',
      },
      {
        label: 'Businesses',
        id: 'businesses',
        icon: UsergroupDeleteOutlined,
        path: '/manage/vendors',
      },
      {
        label: 'Reporting',
        id: 'reporting',
        icon: BarChartOutlined,
        path: '/admin-reports',
      },
      {
        label: 'Profile',
        id: 'profile-admin',
        icon: UserOutlined,
        path: '/profile-admin',
      },
      {
        label: 'Segments',
        id: 'segments',
        icon: UnorderedListOutlined,
        path: '/manage/segment',
      },
      {
        label: 'QR Codes',
        id: 'qrcodes',
        icon: QrcodeOutlined,
        path: '/manage/qrcodes',
      },
      {
        label: 'FAQ',
        id: 'faq',
        icon: FileFilled,
        path: '/manage/faqs',
      },
      {
        label: 'Notification',
        id: 'notification',
        icon: NotificationFilled,
        path: '/notifications',
      },
    ],
  },
  {
    id: 'vendor',
    auth: authRoles.vendor,
    children: [
      {
        label: 'Profile',
        id: 'profile',
        icon: UserOutlined,
        path: '/business-home',
      },
      {
        label: 'Products',
        id: 'products',
        icon: TagFilled,
        path: '/vendors/:vendorId/products',
      },
      {
        label: 'Stores',
        id: 'outlets',
        icon: ShopOutlined,
        path: '/vendors/:vendorId/outlets',
      },
      {
        label: 'Sites',
        id: 'sites',
        icon: QrcodeOutlined,
        path: '/vendors/:vendorId/sites',
      },
      {
        label: 'Devices',
        id: 'adyenDevices',
        icon: MobileOutlined,
        path: '/vendors/:vendorId/devices',
      },
      {
        label: 'Promotions',
        id: 'promotions',
        icon: GiftFilled,
        path: '/vendors/:vendorId/promotions',
      },
      {
        label: 'Loyalty',
        id: 'loyalty',
        icon: GiftFilled,
        path: '/vendors/:vendorId/loyalty',
      },
      {
        label: 'Users',
        id: 'users',
        icon: UsergroupAddOutlined,
        path: '/vendors/:vendorId/users',
        child: ['managers', 'staff'],
      },
      {
        label: 'Reporting',
        id: 'reports',
        icon: FileTextOutlined,
        path: '/vendors/:vendorId/reports',
      },
      {
        label: 'Payment Link',
        id: 'paymentLinks',
        icon: LinkOutlined,
        path: '/vendors/:vendorId/payment-links',
      },
      {
        label: 'Feedback',
        id: 'feedback',
        icon: FileExclamationFilled,
        path: '/vendors/:vendorId/feedback',
      },
      // {
      //   label: "Notification",
      //   id: "notifications",
      //   icon: NotificationFilled,
      //   path: "/notifications",
      // },
    ],
  },
  {
    id: 'external',
    auth: authRoles.external,
    children: [
      {
        label: 'Profile',
        id: 'profile',
        icon: UserOutlined,
        path: '/profile',
      },
      {
        label: 'Stores',
        id: 'outlets',
        icon: ShopOutlined,
        path: '/external/outlets',
      },
    ],
  },
];

export const setPermissionByUser = (
  vendorId: string,
  employeeRef: String | null,
  setPermissions: any
) => {
  if (!vendorId) return;
  if (employeeRef) {
    managerService
      .getOneManager(String(vendorId), String(employeeRef))
      .then((res: any) => {
        if (
          res.data &&
          res.data.stockAccessType &&
          res.data.stockAccessType.length
        )
          setPermissions(res.data.stockAccessType);
        return;
      });
  } else {
    setPermissions(['CREATE-EDIT-MASTER-STOCK-LIST']);
    return;
  }
};

export const buildConfigArray = (
  vendorDetails: Vendor,
  isAdmin: boolean,
  role: any,
  reportQuery: { isInvitedStoreExistsForSites: boolean },
  permissions: string[]
) => {
  const isStockLocationActive = vendorDetails?.isStockLocations;
  const isStockManagementActive = vendorDetails?.isStockManagementActive;
  const isMicroserviceActive = vendorDetails?.isMicroserviceActive;
  const isMerchandiseActive = vendorDetails?.isMerchandiseEnabled;
  const isPaymentLinkActive = vendorDetails?.isPaymentLinkEnabled;
  const isPowerBIDashboardActive = vendorDetails?.enablePowerBIDashboard;
  const isInvitedStoreExistsForSites =
    reportQuery?.isInvitedStoreExistsForSites || false;
  const salesSummaryPath = vendorDetails?.activeNewPnLStructure
    ? '/vendors/:vendorId/reports/sales-summary'
    : '/vendors/:vendorId/reports/profit-and-loss';
  const isMasterAccessible =
    !(role == AppUserType.MANAGER) ||
    (role == AppUserType.MANAGER &&
      permissions.includes('CREATE-EDIT-MASTER-STOCK-LIST'));
  const commonMicroServiceProductsSubModule = [
    {
      label: 'Custom Attributes',
      id: 'customAttributes',
      path: '/vendors/:vendorId/microservice-products/custom-attributes',
    },
    {
      label: 'Menus',
      id: 'menus',
      path: '/vendors/:vendorId/microservice-products/menus',
    },
  ];

  let productsModule: any = {
    label: 'Products',
    id: 'products',
    icon: TagsOutlined,
    path: '/vendors/:vendorId/products',
  };

  if (isMicroserviceActive) {
    if (isMerchandiseActive) {
      if (!isStockLocationActive && !isStockManagementActive) {
        productsModule = {
          label: 'Products',
          id: 'microserviceproducts',
          icon: TagsOutlined,
          path: '',
          children: [
            {
              label: 'F&B Products',
              id: 'fandb-products',
              path: '',
              children: [
                {
                  label: 'Items List',
                  id: 'pms-stock',
                  path: '/vendors/:vendorId/microservice-products/pms-stock',
                },
                ...commonMicroServiceProductsSubModule,
              ],
            },
            ...(isMerchandiseActive
              ? [
                  {
                    label: 'Merchandise',
                    id: 'merchandise',
                    path: '/vendors/:vendorId/merchandise',
                  },
                ]
              : []),
          ],
        };
      } else if (!isStockLocationActive && isStockManagementActive) {
        productsModule = {
          label: 'Products',
          id: 'microserviceproducts',
          icon: TagsOutlined,
          path: '',
          children: [
            {
              label: 'F&B Products',
              id: 'fandb-products',
              path: '',
              children: [
                {
                  label: 'Stock List',
                  id: 'pms-stock',
                  path: '/vendors/:vendorId/microservice-products/pms-stock',
                },
                // {
                //   label: "Stock Actions",
                //   id: "stock-actions",
                //   path: "/vendors/:vendorId/microservice-products/stock-actions",
                // },
                ...commonMicroServiceProductsSubModule,
                {
                  label: 'Suppliers P/O',
                  id: 'suppliers-po',
                  path: '/vendors/:vendorId/microservice-products/suppliers-po',
                },
              ],
            },
            ...(isMerchandiseActive
              ? [
                  {
                    label: 'Merchandise',
                    id: 'merchandise',
                    path: '/vendors/:vendorId/merchandise',
                  },
                ]
              : []),
          ],
        };
      } else if (isStockLocationActive && !isStockManagementActive) {
        productsModule = {
          label: 'Products',
          id: 'microserviceproducts',
          icon: TagsOutlined,
          path: '',
          children: [
            {
              label: 'F&B Products',
              id: 'fandb-products',
              path: '',
              children: [
                ...(isMasterAccessible
                  ? [
                      {
                        label: 'Master Stock List',
                        id: 'pms-stock',
                        path: '/vendors/:vendorId/microservice-products/pms-stock',
                      },
                    ]
                  : []),
                {
                  label: 'Stock Locations',
                  id: 'pms-stock-locations',
                  path: '/vendors/:vendorId/microservice-products/stock-location',
                },
                ...commonMicroServiceProductsSubModule,
              ],
            },
            ...(isMerchandiseActive
              ? [
                  {
                    label: 'Merchandise',
                    id: 'merchandise',
                    path: '/vendors/:vendorId/merchandise',
                  },
                ]
              : []),
          ],
        };
      } else if (isStockLocationActive && isStockManagementActive) {
        productsModule = {
          label: 'Products',
          id: 'microserviceproducts',
          icon: TagsOutlined,
          path: '',
          children: [
            {
              label: 'F&B Products',
              id: 'fandb-products',
              path: '',
              children: [
                ...(isMasterAccessible
                  ? [
                      {
                        label: 'Master Stock List',
                        id: 'pms-stock',
                        path: '/vendors/:vendorId/microservice-products/pms-stock',
                      },
                    ]
                  : []),
                {
                  label: 'Stock Locations',
                  id: 'pms-stock-locations',
                  path: '/vendors/:vendorId/microservice-products/stock-location',
                },
                ...commonMicroServiceProductsSubModule,
                {
                  label: 'Suppliers P/O',
                  id: 'suppliers-po',
                  path: '/vendors/:vendorId/microservice-products/suppliers-po',
                },
              ],
            },
            ...(isMerchandiseActive
              ? [
                  {
                    label: 'Merchandise',
                    id: 'merchandise',
                    path: '/vendors/:vendorId/merchandise',
                  },
                ]
              : []),
          ],
        };
      }
    } else {
      if (!isStockLocationActive && !isStockManagementActive) {
        productsModule = {
          label: 'Products',
          id: 'microserviceproducts',
          icon: TagsOutlined,
          path: '',
          children: [
            {
              label: 'Items List',
              id: 'pms-stock',
              path: '/vendors/:vendorId/microservice-products/pms-stock',
            },
            ...commonMicroServiceProductsSubModule,
          ],
        };
      } else if (!isStockLocationActive && isStockManagementActive) {
        productsModule = {
          label: 'Products',
          id: 'microserviceproducts',
          icon: TagsOutlined,
          path: '',
          children: [
            {
              label: 'Stock List',
              id: 'pms-stock',
              path: '/vendors/:vendorId/microservice-products/pms-stock',
            },
            // {
            //   label: "Stock Actions",
            //   id: "stock-actions",
            //   path: "/vendors/:vendorId/microservice-products/stock-actions",
            // },
            ...commonMicroServiceProductsSubModule,
            {
              label: 'Suppliers P/O',
              id: 'suppliers-po',
              path: '/vendors/:vendorId/microservice-products/suppliers-po',
            },
          ],
        };
      } else if (isStockLocationActive && !isStockManagementActive) {
        productsModule = {
          label: 'Products',
          id: 'microserviceproducts',
          icon: TagsOutlined,
          path: '',
          children: [
            ...(isMasterAccessible
              ? [
                  {
                    label: 'Master Stock List',
                    id: 'pms-stock',
                    path: '/vendors/:vendorId/microservice-products/pms-stock',
                  },
                ]
              : []),
            {
              label: 'Stock Locations',
              id: 'pms-stock-locations',
              path: '/vendors/:vendorId/microservice-products/stock-location',
            },
            ...commonMicroServiceProductsSubModule,
          ],
        };
      } else if (isStockLocationActive && isStockManagementActive) {
        productsModule = {
          label: 'Products',
          id: 'microserviceproducts',
          icon: TagsOutlined,
          path: '',
          children: [
            ...(isMasterAccessible
              ? [
                  {
                    label: 'Master Stock List',
                    id: 'pms-stock',
                    path: '/vendors/:vendorId/microservice-products/pms-stock',
                  },
                ]
              : []),
            {
              label: 'Stock Locations',
              id: 'pms-stock-locations',
              path: '/vendors/:vendorId/microservice-products/stock-location',
            },
            ...commonMicroServiceProductsSubModule,
            {
              label: 'Suppliers P/O',
              id: 'suppliers-po',
              path: '/vendors/:vendorId/microservice-products/suppliers-po',
            },
          ],
        };
      }
    }
  } else {
    productsModule = {
      label: 'Products',
      id: 'products',
      icon: TagsOutlined,
      children: [
        {
          label: 'F&B Products',
          id: 'products',
          path: '/vendors/:vendorId/products',
        },
        ...(isMerchandiseActive
          ? [
              {
                label: 'Merchandise',
                id: 'merchandise',
                path: '/vendors/:vendorId/merchandise',
              },
            ]
          : []),
      ],
    };
  }

  if (role === AppUserType.VENDOR || role === AppUserType.MANAGER) {
    return [
      ...(role === AppUserType.VENDOR
        ? [
            {
              label: 'Homepage',
              id: 'business-home',
              icon: HomeOutlined,
              path: '/business-home',
            },
          ]
        : []),
      ...(role === AppUserType.MANAGER
        ? [
            {
              label: 'Profile',
              id: 'profile',
              icon: UserOutlined,
              path: '/profile',
            },
          ]
        : []),
      {
        label: 'Reporting',
        id: 'reports',
        icon: LineChartOutlined,
        path: '',
        children: [
          ...(isPowerBIDashboardActive ?
            [{
              label: "Dashboard",
              id: "dashboard",
              path: "",
              children: [
                {
                  label: 'Sales',
                  id: 'dashboard-sales',
                  path: '/vendors/:vendorId/reports/dashboard-sales',
                },
                {
                  label: 'Product',
                  id: 'dashboard-product',
                  path: '/vendors/:vendorId/reports/dashboard-product',
                },
              ]
            }] : []),
          {
            label: 'Sales',
            id: 'sales-reports',
            path: '',
            children: [
              {
                label: 'Sales Summary',
                id: 'profit-and-loss',
                path: salesSummaryPath,
              },
              {
                label: 'Products',
                id: 'reports-product-detail',
                path: '/vendors/:vendorId/reports/product-detail',
              },
              {
                label: 'Menu',
                id: 'menu-detail-report',
                path: isInvitedStoreExistsForSites
                  ? '/vendors/:vendorId/reports/menu-detail-report'
                  : '/vendors/:vendorId/reports/menu-detail-report/own-menu-detail',
              },
              {
                label: 'Orders',
                id: 'order-report',
                path: '/vendors/:vendorId/reports/order-report',
              },
              // {
              //   label: "Promotions",
              //   id: "promotion-report",
              //   path: "",
              // },
              // {
              //   label: "Memberships",
              //   id: "membership-report",
              //   path: "",
              // },
            ],
          },
          {
            label: 'Inventory (F&B)',
            id: 'inventory-fandb',
            path: '',
            children: [
              {
                label: 'Stock',
                id: 'new-stock-report',
                path: '/vendors/:vendorId/reports/new-stock-report',
              },
              {
                label: 'Stock Movement',
                id: 'fandb-stock-movement-report',
                path: '/vendors/:vendorId/reports/stock-movement-report',
              },
            ],
          },
          ...(isMerchandiseActive
            ? [
                {
                  label: 'Inventory (Merch)',
                  id: 'inventory-merch',
                  path: '',
                  children: [
                    {
                      label: 'Stock',
                      id: 'merch-stock-report',
                      path: '/vendors/:vendorId/reports/merch-stock-report',
                    },
                    {
                      label: 'Stock Movement',
                      id: 'merch-stock-movement-report',
                      path: '/vendors/:vendorId/reports/merch-stock-movement-report',
                    },
                  ],
                },
              ]
            : []),
          {
            label: 'Users',
            id: 'userReport',
            path: '',
            children: [
              {
                label: 'Employees',
                id: 'employee-report',
                path: '/vendors/:vendorId/reports/employee-report',
              },
              {
                label: 'Customer',
                id: 'customer',
                path: '/vendors/:vendorId/reports/customer',
              },
            ],
          },
          {
            label: 'Fees and Payouts',
            id: 'feeAndPayout',
            path: '',
            children: [
              {
                label: 'Payment Invoices',
                id: 'payment-invoices',
                path: '/vendors/:vendorId/reports/payment-invoices',
              },
              {
                label: 'Payouts',
                id: 'payout-report',
                path: '/vendors/:vendorId/reports/payout-report',
              },
            ],
          },
          {
            label: 'Other Reports',
            id: 'otherReport',
            path: '',
            children: [
              {
                label: 'Custom CSV',
                id: 'custom-csv',
                path: '/vendors/:vendorId/reports/custom-csv',
              },
              ...(isAdmin
                ? [
                    {
                      label: 'Cash History',
                      id: 'cash-history',
                      path: '/vendors/:vendorId/reports/cash-history',
                    },
                    {
                      label: 'Cash Position',
                      id: 'cash-position',
                      path: '/vendors/:vendorId/reports/cash-position',
                    },
                    {
                      label: 'Print Jobs',
                      id: 'print-job',
                      path: '/vendors/:vendorId/reports/print-job',
                    },
                    {
                      label: 'Print Logs',
                      id: 'print-log',
                      path: '/vendors/:vendorId/reports/print-log',
                    },
                  ]
                : []),
            ],
          },
        ],
      },
      {
        label: 'Business Settings',
        id: 'business-settings',
        icon: ClusterOutlined,
        path: '',
        children: [
          ...(role === AppUserType.VENDOR
            ? [
                {
                  label: 'Business Details',
                  id: 'business-profile',
                  path: '/profile',
                },
              ]
            : []),
          {
            label: 'Merchant ID',
            id: 'merchant-service-account',
            path: '/vendors/:vendorId/merchant-service-account',
          },
          {
            label: 'Users',
            id: 'users',
            path: '/vendors/:vendorId/users',
            child: ['managers', 'staff'],
          },
          {
            label: 'Devices',
            id: 'adyenDevices',
            path: '/vendors/:vendorId/devices',
          },
          ...(isMicroserviceActive ? [ {
            label: 'Taxes',
            id: 'taxes',
            path: '/vendors/:vendorId/microservice-products/taxes',
          }] : []),
        ],
      },
      {
        label: 'Pages',
        id: 'pages',
        type: 'heading',
        path: '',
      },
      {
        ...productsModule,
      },
      {
        label: 'Stores',
        id: 'outlets',
        icon: ShopOutlined,
        path: '/vendors/:vendorId/outlets',
      },
      {
        label: 'Sites',
        id: 'sites',
        icon: QrcodeOutlined,
        path: '/vendors/:vendorId/sites',
      },
      // {
      //   label: "Customer Interfaces",
      //   id: "customer-interface",
      //   icon: GlobalOutlined,
      //   path: "",
      //   children: [

      //   ]
      // },
      ...(isPaymentLinkActive
        ? [
            {
              label: 'Payment Link',
              id: 'paymentLinks',
              icon: LinkOutlined,
              path: '/vendors/:vendorId/payment-links',
            },
          ]
        : []),
      {
        label: 'Marketing',
        id: 'marketing',
        icon: GlobalOutlined,
        path: '',
        children: [
          // {
          //   label: "Customers",
          //   path: "/manage/customers",
          //   id: "customers"
          // },
          // {
          //   label: "Customer Segmentation",
          //   id: "customerSegmen",
          //   path: "",
          //   children: [
          //     {
          //       label: "Customer Tag",
          //       id: "customerTag",
          //       path: "/manage/customers/tag"
          //     },
          //     {
          //       label: "Customer Group",
          //       id: "customerGroup",
          //       path: "/manage/customers/group"
          //     }
          //   ]
          // },
          {
            label: 'Vouchers',
            path: '/manage/vouchers',
            id: 'vouchers',
          },
          {
            label: 'Promotions',
            id: 'promotions',
            path: '/vendors/:vendorId/promotions',
          },
          // {
          //   label: "Membership",
          //   id: "membership",
          //   path: "",
          // },
          {
            label: 'Loyalty',
            id: 'loyalty',
            path: '/vendors/:vendorId/loyalty',
          },
          // {
          //   label: "Gift cards/Vouchers",
          //   id:"gift-vouchers",
          //   path: ""
          // },
          {
            label: 'Feedback',
            id: 'feedback',
            path: '/vendors/:vendorId/feedback',
          },
        ],
      },
    ];
  } else if (role === AppUserType.ADMIN) {
    return [
      {
        label: 'Administrators',
        id: 'admins',
        icon: UsergroupDeleteOutlined,
        path: '/manage/admins',
      },
      {
        label: 'Businesses',
        id: 'businesses',
        icon: UsergroupDeleteOutlined,
        path: '/manage/vendors',
      },
      {
        label: 'Reporting',
        id: 'reporting',
        icon: BarChartOutlined,
        path: '/admin-reports',
      },
      {
        label: 'Profile',
        id: 'profile-admin',
        icon: UserOutlined,
        path: '/profile-admin',
      },
      {
        label: 'Segments',
        id: 'segments',
        icon: UnorderedListOutlined,
        path: '/manage/segment',
      },
      {
        label: 'QR Codes',
        id: 'qrcodes',
        icon: QrcodeOutlined,
        path: '/manage/qrcodes',
      },
      {
        label: 'FAQ',
        id: 'faq',
        icon: FileFilled,
        path: '/manage/faqs',
      },
      {
        label: 'Notification',
        id: 'notification',
        icon: NotificationFilled,
        path: '/notifications',
      },
    ];
  } else if (role == AppUserType.EXTERNAL) {
    return [
      {
        label: 'Profile',
        id: 'profile',
        icon: UserOutlined,
        path: '/profile',
      },
      {
        label: 'Stores',
        id: 'outlets',
        icon: ShopOutlined,
        path: '/external/outlets',
      },
    ];
  }
};

export default navigationConfig;
